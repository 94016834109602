 import React from 'react'
import './home.css'
import { NavLink,useHistory} from 'react-router-dom'
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor-v2";
// import { useInViewport } from 'react-in-viewport';
// import handleViewport from "react-in-viewport";
// import  {CountUp } from 'use-count-up'


// import Slider from 'react-slick'

const Home=() =>{
//  const fname = useParams()
    // const myRef = useRef();
    // const {
    //   inViewport,
    //   enterCount,
    //   leaveCount,
     
    // } = useInViewport(
    //   myRef,
    //   options,
    //   config = { disconnectOnLeave: false },
    // props
    // );
// const [seconds, setSeconds] = useState(0);

// useEffect(() => {
//    window.addEventListener("onMouseEnter", counter);
//    return(()=>{
// window.removeEventListener(' onMouseLeave', counter)
//    })
   
// },[seconds])
// useEffect(() => {
//         if (seconds < 50) {
//                 setTimeout(() => setSeconds(seconds + 1), 50);
//               } else {
//                 setSeconds('50+');
//               }
// }, [seconds])
// const counter=()=>{
  
//     if (seconds < 50) {
//         setTimeout(() => setSeconds(seconds + 1), 10);
//       } else {
//         setSeconds('50+');
//       }
    
// }
// useEffect(() => {
//     function watchScroll() {
//       window.addEventListener("mouseenter", counter);
//     }
//     watchScroll();
//     return () => {
//       window.removeEventListener("mouseenter", counter);
//     };
//   });

// window.addEventListener('mousemove', (event) => {
//      if (seconds < 50) {
//                 setTimeout(() => setSeconds(seconds + 1), 1000);
//               } else {
//                 setSeconds('50');
//               }
// })
const history=useHistory()

const callBackButton=()=>{
    history.push("/contactus")
}
const navigateToSignUp = () => {
    window.open('https://user.myfin.in/#/register', '_blank', 'noopener,noreferrer');
  };

    return (
        <>
         {/* <section ref={myRef}>
      <div className="content" style={this.getStyle()}>
        <h1>Hello</h1>
        <p>{`Enter viewport: ${enterCount} times`}</p>
        <p>{`Leave viewport: ${leaveCount} times`}</p>
      </div>
    </section> */}
    
    {/* <img src="ASSETS/HEADER.png" alt="" className="img-fluid w-100 "/>   */}
           <div className="bgimg img-fluid">
                <div className="layer">
                <div className="container text-left text-white hearderset">
                    <h1 className="container display-5 " >IMMEDIATE MONEY... </h1>
                    <h1 className="container display-5 font-weight-bold" >... for your Immediate Needs</h1>
                    <p className="p-3">Loans up to Rs. 2 Lakhs* in just a few steps against your salary
                    <br/> Get money credited to your account in just a few hours</p>
                   
                    <button className="btn btn-md apply2 text-white mt-lg-3 d-none d-sm-block" onClick={navigateToSignUp}>APPLY NOW</button><br/>
                  
                   <div className="container row p-lg-2 p-md-4 guarantors-row">
                        <div className="col-lg-1">

                        </div>
                        <div className="col-lg-2 col-md-3 col-12 col-sm-12 mt-2 grid ">
                        <div className="guarantors">
                            <img src="ASSETS/group 30.png" alt="" className="img-fluid"/>
                         
                         </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-12 col-sm-12  mt-2 grid">
                        <div className="guarantors">
                        <img src="ASSETS/group 31.png" alt="" className="img-fluid"/>
                        </div> 
                            </div>
                            <div className="col-lg-2 col-md-3 col-13 col-sm-12 mt-2  grid">
                            <div className="guarantors ">
                            <img src="ASSETS/group 32.png" alt="" className="img-fluid"/>
                                </div> 
                            </div>
                    </div>
                   
                  
                    <p className="container  pt-3 pb-5">*Terms & Conditions Apply</p><br/>
                  
                </div>
                <div className="button">
                <button className=" btn btn-sm callback text-white  d-none d-sm-none d-md-block d-xl-block d-lg-none" onClick={callBackButton}>CALL BACK</button>
                </div>
                <div className="icon">
               
                    <img src="ASSETS/Group 34.png" alt="" className="img-fluid d-none d-sm-none d-md-none d-xl-block d-lg-none" onClick={callBackButton} role="button"/>

                </div>
               
                </div>
            </div>
            {/* <section ref={myRef}>
      <div className="content" style={this.getStyle()}>
        <h1>Hello</h1>
        <p>{`Enter viewport: ${enterCount} times`}</p>
        <p>{`Leave viewport: ${leaveCount} times`}</p>
      </div>
    </section> */}
            <section className="container-fluid why-myfin mt-2">
            <div className="loanimg img-fluid">
            <h1 className="font-weight-bold p-2 ">WHY <span>MYFIN LOANS ?</span></h1>
            
            <div className="row ">
                <div className="col-lg-4 col-md-12 col-sm-12 col-12 section1">
                <img src="ASSETS/hand.png" alt="" className="img-fluid"/>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 col-12 ">
                    <div className="row section ml-lg-5">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 mt-lg-4 mt-3 ">
                        <img src="ASSETS/Ellipse 7.svg" alt=""></img>
                            <h6 className="mt-lg-3 mt-3 font-weight-bold ">IMMEDIATE <br/>LOANS</h6>
                            <hr className="style1"/>
                        </div>
                        <div className="verticalline"></div>
                       
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 mt-lg-4 mt-3">
                          <img src="ASSETS/Group 164.svg" alt=""></img>
                            <h6 className="mt-lg-3 mt-3 font-weight-bold ">SIMPLE 3-STEP <br/>PROCESS</h6>
                            <hr className="style1"/>
                        </div>
                        <div className="verticalline "></div>
                    
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 mt-lg-4 mt-3">
                               <img src="ASSETS/Group 162.svg" alt=""></img>
                            <h6 className="mt-lg-3 mt-3 font-weight-bold ">NO SECURITY <br/> REQUIRED</h6>
                            <hr className="style1"/>
                        </div>
                      
                     
                        
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 mt-lg-3 mt-3 ">
                        <img src="ASSETS/Group 168.svg" alt=""></img>
                           <h6 className="mt-lg-3 mt-3 font-weight-bold ">NO GUARANTORS REQUIRED</h6>
                        </div>
                        <div className="verticalline "></div>
                        <div className="col-lg-3 mt-lg-3 col-md-4 col-sm-6 col-6 mt-3 ">
                          <img src="ASSETS/Group 165.svg" alt=""></img>
                           <h6 className="mt-lg-3 mt-3 font-weight-bold ">QUICK AND IMMEDIATE</h6>
                        </div>
                        <div className="verticalline "></div>
                        <div className="col-lg-3 mt-lg-3 col-md-4 col-sm-6 col-6 mt-3 ">
                        <img src="ASSETS/Group 166.svg" alt=""></img>
                            <h6 className="mt-lg-3 mt-3 font-weight-bold ">STRESS-FREE</h6>
                        </div>
                        

                    </div>
                </div>
            </div>
            <div className="container installments mt-3 mb-5">
                <div className="row mb-4 ">
                    <div className="col-lg-9 col-md-8 col-sm-12 col-12  p-4 mx-auto">
                    <h6 className="text-dark font-weight-bold ">Don't ask your Friends/Family/Relatives or Colleagues for Money anymore...</h6>
                    <h6 className="text-dark font-weight-bold ">just demand from myfin and repay in simple installments</h6>
                    
                    </div>
                   
                    <div className="col-lg-3 col-md-3 col-12 col-sm-12 my-auto  installmentdiv">
                    <button className="btn btn-md apply4 text-white mx-auto  d-sm-none d-none d-md-block" onClick={navigateToSignUp}>APPLY NOW</button>
                   
                    </div>
                  
                </div>
                
            </div>
            <button className="btn btn-md apply5 text-white mx-auto d-md-none my-md-auto" onClick={navigateToSignUp}>APPLY NOW</button>
            </div>
            
            </section>
            <section className="immediateimg img-fluid img-responsive ">
            <div className="layer1 ">
            <div className="container-fluid immediate_needs need">
           
        
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-12 col-sm-12 p-3 need_left_row">
                    <h4 className="p-3 text-left font-weight-bold ml-lg-4"><b>AN IMMEDIATE ONLINE LOAN DESIGNED TO MEET YOUR IMMEDIATE NEEDS!</b></h4>
                      <div className="row ">
                     
                          <div className="col-lg-5 col-md-6 col-12 col-sm-12 ml-lg-5 p-3 p-lg-2 needs ">
                             <div className='row needsrow pl-1 pl-lg-0 pl-md-0 pr-md-2'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-md-10 col-sm-11 col-10 ">
                                <h6 className=" text-left font-weight-bold p-1 ">Pay Unforeseen Medical Bills</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Make Credit card payments</h6>
                                    </div>
                                </div>
                             
                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Pay Children's school fees</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Go on a family holiday</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Rent or deposit payment</h6>
                                    </div>
                                </div>
                           {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills</h6> */}
                           {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Make Credit card payments</h6> */}
                           {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Pay Children's school fees</h6> */}
                           {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Go on a family holiday</h6> */}
                           {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Rent or deposit payment</h6> */}
                         
                          </div>
                          
                          <div className="col-lg-6 col-md-6 col-12 col-sm-12 p-3 p-lg-1 needs">
                          <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Sudden vehicle payment</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0 mt-lg-3 mt-xl-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10 ">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Pay your EMIs on <br className="d-lg-none d-md-block d-none"/>time</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0 mt-lg-3 mt-xl-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Celebrate festivals with family</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Purchase household appliances</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-0"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Gift someone on their wedding/birthday</h6>
                                    </div>
                                </div>
                              {/* <h6 className=" text-left  mt-lg-0 font-weight-bold"><span>◉</span>&nbsp;&nbsp;Sudden vehicle payment</h6> */}
                              {/* <h6 className=" text-left font-weight-bold "><span>◉</span>&nbsp;&nbsp;Pay your EMIs on <br className="d-lg-none d-md-block d-none"/>time</h6> */}
                              {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Celebrate festivals with family</h6> */}
                              {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Purchase household appliances</h6> */}
                              {/* <h6 className=" text-left font-weight-bold"><span>◉</span>&nbsp;&nbsp;Gift someone on their wedding/birthday</h6> */}
                              </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12 col-sm-12 meetheading">
                    <h5 className="pl-3 text-left ml-lg-2 mr-md-5 font-weight-bold">T0 MEET ANY URGENT REQUIREMENT OF MONEY</h5>
                    </div>
                    </div>
                    
                   
                    <div className="col-lg-4 col-12 col-md-4 col-sm-12 money1  half-circle d-none d-md-block mr-lg-5">
                        <img src="ASSETS/money.jpg" alt="" />
                        </div>
                        <div className="col-lg-2 col-12 col-md-2 col-sm-12 money2 half-circle d-md-none ">
                        <img src="ASSETS/money2.png" alt="" className="img-fluid" />
                        </div>
                </div>
                </div>
               
                </div>
            </section>
            <section className="container-fluid p-5 disbursed "  >
                <div className="row mb-5 " >
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <img src="ASSETS/Group 93.png" alt=""/>
                        
                        <h1 className="font-weight-bold mb-4" >
                        {/* <p>{`Enter viewport: ${enterCount} times`}</p>
        <p>{`Leave viewport: ${leaveCount} times`}</p> */}
                            {/* <VisibilitySensor partialVisibility offset={{bottom: 200}}>
                                {({ isVisible})=>(
                        <div style={{height: 30}} >
                            {isVisible ? <CountUp end={`50`} duration={1} suffix={'+'}  style={{color: 'red'}}/> : null}
                            </div>
                                )}
                            </VisibilitySensor> */}
                   {/* <CountUp isCounting end={50} start={0}  duration={3.2} /> */}
                 
                            <VisibilitySensor partialVisibility offset={{bottom: 200}}>
                                {({ isVisible})=>(
                        <div  style={{height: 30}} >
                            {isVisible ? <CountUp  end={1000} duration={1} suffix={'+'} style={{color: 'red'}}/> : null}
                            </div>
                                )}
                            </VisibilitySensor>

                       
                        </h1>
                        
                        <h6 className="font-weight-bold">Numbers of loans disbursed</h6>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
                        <img src="ASSETS/br-disbursement.png" alt=""/>
                        <h1 className="font-weight-bold mb-4">
                        <VisibilitySensor partialVisibility offset={{bottom: 200}}>
                                {({ isVisible})=>(
                        <div style={{height: 30}} >
                            {isVisible ? <CountUp end={4} duration={1}  suffix={'cr+'} style={{color: 'red'}}/> : null}
                            </div>
                                )}
                            </VisibilitySensor>
                        </h1>
                        <h6 className="font-weight-bold">Cumulative loans disbursed</h6>
                    </div>
                   
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-2">
                    <img src="ASSETS/images (1).png" alt=""/>
                        <h1 className="font-weight-bold mb-4 ">
                            <VisibilitySensor partialVisibility offset={{bottom: 200}}>
                                {({ isVisible})=>(
                        <div  style={{height: 30}} >
                            {isVisible ? <CountUp  end={50} duration={1} suffix={'+'} style={{color: 'red'}}/> : null}
                            </div>
                                )}
                            </VisibilitySensor>

                        </h1>
                        <h6 className="font-weight-bold">Numbers of corporate tie-ups</h6>
                    </div>
                </div>
            </section>

            <section className="container-fluid business d-none d-md-block d-lg-block">
    
                <div className="container text-left hearderset1 d-none d-sm-block my-auto">
                    <h1 className="container font-weight-bolder my-auto ">More structured financial products <br/>to suit every financial need coming soon...</h1>
                   
                    </div>
                    

              
            </section>
            <section className="container-fluid business1 d-lg-none  d-md-none d-block">

                    <div className="container text-left hearderset1  my-auto">
                    <h1 className="container font-weight-bold my-auto text-center">More structured financial products to suit every financial need coming soon...</h1>
                   
                    </div>
                    </section>
                    <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
                    {/* <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
                <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
         
        </>
    )
}

export default Home
