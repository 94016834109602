import React,{useState, useEffect} from 'react'
import './ContactUs.css'
import { NavLink} from 'react-router-dom'
import axios from 'axios'
import urlData from "../UrlData"
const ContactUs=() =>{
    const [fname, setFName] = useState("")
    const [lname, setlName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneno, setPhoneno] = useState("")
    const [query, setQuery] = useState("")
    const [emailErrorCode, setemailErrorCode]=useState("")
    const [mobileErrorCode, setmobileErrorCode]=useState("")
    const [id, setId]=useState([])
    const [listItem,setListItem]=useState([])
    
    

    const ContactForm=(e)=>{
        e.preventDefault()
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var mnumber = /^[0-9\b]+$/ ;
    
        if(fname==""||lname==""||email==""||phoneno==""||query=="" ){
          alert("Please fill up all details")
        }
        else if (reg.test(email) == false) 
        {
            setemailErrorCode('Invalid Email Address')
            return false;
        }
        else if(mnumber.test(phoneno) == false)
        {
          setmobileErrorCode('Please enter only numbers')
        }
        else if(phoneno.length !==10){
          
          setmobileErrorCode('Please enter valid phone number')
        }
        else{
     var data={}
     data.firstName=fname
     data.lastName=lname
     data.email=email
     data.contactNo=phoneno
     data.query=query
     
     axios({
         method: "post",
         url: new URL(urlData + "applicants/contactUsQuery"),
         data:data

     }).then(response=>{
         console.log('create',response)
         alert("Form Submitted Successfully")
         window.location.reload();
     }).catch(error=>{
         console.log(error)
     })
    }
}
const emailhandleChnage=(e)=>{
  
    setEmail(e.target.value)
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(e.target.value) == false) 
      {// alert('Invalid Email Address');
          setemailErrorCode('Invalid Email Address')
          return false;
          
      }
      else{
        setemailErrorCode("Valid Email Address")
        
        
      }
  }
  const mobilehandleChnage=(e)=>{

    var mnumber = /^[0-9\b]+$/ ;
    setPhoneno(e.target.value)
    if(mnumber.test(e.target.value) == false)
      {
        setmobileErrorCode('Please enter only numbers')
      }
      else if(e.target.value.length !=10) {
        
        setmobileErrorCode('Please enter valid mobile number')
      }
      else{
        setmobileErrorCode('Mobile No. is Valid')
      }
  }
  // const posts=listItem.map(post=>
  //   <tbody key={post._id}>
  //    <tr>
  //       <td className="text-center">{post.firstName}</td>
  //       <td className="text-center">{post.lastName}</td>
  //       <td className="text-center">{post.email}</td>
  //       <td className="text-center">{post.phoneNo}</td>
        
                      
  //   </tr>
  //   </tbody>)

    return (
        <>
        <hr className="container-fluid abouthr d-none d-lg-block"></hr>
         
        <div className="contactusimg img-fluid d-none d-lg-block d-md-block">
        <div className="container text-left  contacthearderset">
       <h2 className="container display-5 font-weight-bold font-weight-bold text-white">CONTACT US</h2>
      </div>
        </div>
        <div className="contactusimg img-fluid d-sm-block d-block d-lg-none d-md-none">
        <div className="container text-left  contacthearderset">
       <h2 className="container display-5 font-weight-bold font-weight-bold text-white">CONTACT US</h2>
      </div>
        </div>
        {/* <div className="trainglecontactus d-none d-xl-block d-lg-none"></div> */}
        <section className="container contact_left">
            <div className="row pt-5 mt-4">
                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                {/* <h2 className="text-lg-left font-weight-bold contact_heading">MYFIN</h2> */}
                <div className="imgdiv_contactus mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1" alt=""/>
                       
                         </div>
                    
                        
                <h4 className="text-center text-md-left font-weight-bold working_hours pt-2">Working Hours</h4>
                <h4 className="text-lg-left text-md-left">Monday - Friday (10:30 am to 5:30 pm)</h4>
                <h4 className="text-lg-left text-md-left font-weight-bold pt-3 holiday">Saturdays and Sundays are holidays</h4>
                <h4 className="text-lg-left text-md-left font-weight-bold office">Our office will remain shut on all bank holidays</h4>
                <h4 className="text-lg-left text-md-left font-weight-bold pt-4"><i className="fa fa-phone mr-3"></i>Call Now</h4>
                <h4 className="text-lg-left text-md-left font-weight-bold pt-3"><i className="fa mr-4">&#xf041;</i>Office Location</h4>
                    </div> 

                <div className="verticalline d-none d-sm-block  ml-lg-5"></div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 contactform pl-lg-5 ml-lg-4 mt-5 mt-lg-0 mt-md-0 ">
                <h4 className="text-lg-left text-md-left font-weight-bold ml-md-4">KINDLY FILL THE FOLLOWING FORM <br/>FOR A QUICK AND EFFECTIVE RESPONSE</h4>
              <form action="" method="post" encType="multipart/form-data">
                 {/* <div className="text-left mb-0 container border border-dark rounded-pill mt-lg-5 mt-5 p-2"> */}
                    <input type="text"  placeholder="First Name" id="ip" className="w-75 container border border-dark rounded-pill  mt-5 p-2 pl-4" name="fname" value={fname} onChange={(e)=>setFName(e.target.value)}/>
                     {/* <h5 className="pl-4 pt-1 font-weight-bold heading">Sales Query</h5> */}

                     {/* <h5 className="pl-4 pb-1">Mail ID</h5>
                     <div className="label1 mt-2">
              <label className="text-left float-left pl-2"><h5>Mail ID</h5></label><input type="text" className="w-75 pb-4  border-none float-right no-border d-none d-lg-block"/>
              </div> */}
                    
         
                 {/* </div> */}
                 {/* <div className="text-left mb-0 container border border-dark rounded-pill mt-3  p-2"> */}
                 <input type="text" placeholder="Last Name" className="w-75 container border border-dark rounded-pill  mt-3 p-2 pl-4" name="lname" value={lname} onChange={(e)=>setlName(e.target.value)}/>
                     {/* <h5 className="pl-4 pt-1 font-weight-bold heading">Customer Grievance</h5>
                   
                     <div className="label1 mt-2">
              <label className="text-left float-left pl-2"><h5>Mail ID</h5></label><input type="text" className="w-75 pb-4 border-none float-right no-border d-none d-lg-block"/>
              </div> */}
                  
                 {/* </div> */}
                 {/* <div className="text-left mb-0 container border border-dark rounded-pill mt-3  p-2"> */}
                 <input type="text" placeholder="Email Address" className="w-75 container border border-dark rounded-pill  mt-3 p-2 pl-4" name="email" value={email} onChange={emailhandleChnage}/>
                 <p className="mt-2 text-center text-lg-left ml-lg-4" style={emailErrorCode!='Valid Email Address'?{color: "red"}: {color: "green"}}><strong>{emailErrorCode}</strong></p>
                     {/* <h5 className="pl-4 pt-1 font-weight-bold heading">Customer Satisfaction</h5>
                    
                     <div className="label1 mt-2">
              <label className="text-left float-left pl-2"><h5>Mail ID</h5></label><input type="text" className="w-75 pb-4 border-none float-right no-border d-none d-lg-block"/>
              </div> */}
                 {/* </div> */}
                 {/* <div className="text-left mb-0 container border border-dark rounded-pill mt-3  p-2"> */}
                 <input type="text" placeholder="Phone No." className="w-75 container border border-dark rounded-pill  p-2 pl-4" name="phoneno" value={phoneno} onChange={mobilehandleChnage}/>
                 <p className="mt-2 text-center text-lg-left ml-lg-4" style={mobileErrorCode!=="Mobile No. is Valid"?{color: "red"}: {color: "green"}}><strong>{mobileErrorCode}</strong></p>
                     {/* <h5 className="pl-4 pt-1 font-weight-bold heading">Corporate Query</h5>
                     
                     <div className="label1 mt-2">
              <label className="text-left float-left pl-2"><h5>Mail ID</h5></label><input type="text" className="w-75 pb-4 border-none float-right no-border d-none d-lg-block"/>
              </div> */}
                 {/* </div> */}
                 {/* <div className="text-left mb-0 container border border-dark rounded-pill mt-3  p-2 quarybox"> */}
                 <textarea type="text" placeholder="Your Query" className="w-75 container border border-dark rounded-pill p-2 pl-4 quarybox" name="query" value={query} onChange={(e)=>setQuery(e.target.value)}/>
                     {/* <h5 className="pl-4 pt-1 font-weight-bold heading">Associate Query</h5>
                     
                     <div className="label1 mt-2">
              <label className="text-left float-left pl-2"><h5>Mail ID</h5></label><input type="text" className="w-75 pb-4 border-none float-right no-border d-none d-lg-block"/>
              </div> */}
                 {/* </div> */}
                     {/* <button className="btn btn-md apply3 text-white ml-2 ">SHARE</button>  */}
                     <button className="mb-0 apply3 text-white border border-dark rounded-pill mt-3 p-2" onClick={ContactForm}>
                         SEND QUERY</button>
                         </form>
                </div>
                
            </div>
        </section>
        <section className="container-fluid bg-light mt-5 mapsection d-none d-lg-block d-md-block">
        <div className="map img-fluid  container-fluid">
            <div className="directionbuttons">
            {/* <button className="btn btn-md apply3 text-white">GET DIRECTION</button> */}
            <img src="ASSETS/Group 282.png" alt="" className="img-fluid"/>
            <img src="ASSETS/Group 283.png" alt="" className="img-fluid sharebutton ml-3"/>
            {/* <button className="btn btn-md apply3 text-white ml-2 ">SHARE</button> */}
        
       
        </div>
        </div>
        </section>
        <section className="container-fluid bg-light mt-5 mapsection d-lg-none d-md-none d-block">
        <div className="map img-fluid  container-fluid">
        <div className="directionbuttons">
        <img src="ASSETS/Group 282.png" alt="" className="img-fluid ml-2"/>
            <img src="ASSETS/Group 283.png" alt="" className="img-fluid sharebutton ml-3"/>
        
       
        </div>
        </div>
      
        </section>
        
        {/* <table className="table table-hover  mb-0  d-sm-table " style={{overflow:"auto"}}>
                <thead className="thead-bordered-bottom">
                  <tr>
                    <th className="text-center">Qualification 
                    
                  </th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Active</th>
                    <th className="text-center">Update</th>
                  </tr>
                </thead>
                {posts}
                  </table> */}
                   <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
{/*           
       <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg ">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
              
               <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
        </>
    )
}

export default ContactUs
