import React, { useState, useEffect } from "react";
import "./Otherloan.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { object } from "prop-types";
import urlData from "../UrlData"
const Otherloan = () => {
  const [emailErrorCode, setemailErrorCode] = useState("");
  const [mobileErrorCode, setmobileErrorCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [designation, setDesignation] = useState("");
  const [employedWithTheCompanySince, setEmployeeWithTheCompanySince] =
    useState("");
  const [netMonthlySalary, setNetMonthlySalary] = useState("");
  const [otherLoanInstalment, setOtherLoanInstalment] = useState("");

  const [loanRequired, setLoanRequired] = useState({
    homeloan: false,
    loanAgainstProperty: false,
    commercialProperty: false,
    personalLoan: false,
  });
  const [propertyLocation, setPropertyLocstion] = useState("");
  const [carpetAreaProperty, setCarpetAreaProperty] = useState("");
  const [estimateMarket, setEstimateMarket] = useState("");
  const [propertyType, setPropertyType] = useState({
    residental: false,
    commercial: false,
  });
  const [construction, setConstruction] = useState({
    underconstruction: false,
    ready: false,
    resale: false,
    owned: false,
  });
  const [loanName, setloanName] = useState("")
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  today = yyyy + '-' + mm + '-' + dd;
  useEffect(() => {
    getData();
  }, []);

  const getData = (date) => {
    var data = {};
    data._id = ["61adbb549f58042e0c28b685", "61adcffb9f58042e0c28b68a"];

    axios({
      method: "post",
      url: "http://localhost:4040/api/master/getSingleOtherLoanUser",
      data: data,
    })
      .then((response) => {
        console.log("response", response);
        console.log("date", date);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OtherLoanFormHandler = (e, date) => {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
     var mnumber = /^[0-9\b]+$/;
    // let checkArray = [];
    // for (var key in loanRequired) {
    //   if (loanRequired[key] === true) {
    //     checkArray.push(key);
    //   }
    // }

    // let checkData = checkArray.toString();
    // console.log(checkData);
    let checkArrayPropertyType = [];
    for (var key in propertyType) {
      if (propertyType[key] === true) {
        checkArrayPropertyType.push(key);
      }
    }

    // let checkArrayConstruction = [];
    // for (var key in construction) {
    //   if (construction[key] === true) {
    //     checkArrayConstruction.push(key);
    //   }
    // }
    // let checkDataConstruction = checkArrayConstruction.toString();
    // console.log(checkDataConstruction);
    if (
      name == "" ||
      email == "" ||
      contactNo == "" ||
      designation == "" ||
      employedWithTheCompanySince == "" ||
      netMonthlySalary == "" ||
      propertyLocation == "" ||
      carpetAreaProperty == "" 
    ) {
      alert("Please fill up all details");
    } else if (reg.test(email) == false) {
      alert("Invalid Email Address");
      return false;
    } else if (mnumber.test(contactNo) == false) {
      alert("Please enter only numbers");
    } else if (contactNo.length !== 10) {
      alert("Please enter valid phone number");
    } else {
      var data = {};
      data.name = name;
      data.email = email;
      data.contactNo = contactNo;
      data.designation = designation;
      data.employedWithTheCompanySince = employedWithTheCompanySince;
      data.netMonthlySalary = netMonthlySalary;
      data.otherLoanInstalment = otherLoanInstalment;
      data.loanRequired =loanName
      data.propertyLocation = propertyLocation;
      data.propertyType = checkArrayPropertyType[0];
      data.carpetAreaOfProperty = carpetAreaProperty;

      axios({
        method: "post",
        url: new URL(urlData + "applicants/sendLoanEnquiryData"),
        data: data,
      })
        .then((response) => {
          console.log("create", response);
          alert("Your Form Successfully Submitted.");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const emailhandleChnage = (e) => {
    setEmail(e.target.value);
  };
  const mobilehandleChnage = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setContactNo(e.target.value);
    }
  };
  //       const Checked=(e)=>{
  //           var {name, checked}=e.target;
  // console.log(e)
  //           setLoanRequired((e)=>{
  //               var SelectedProperty=e.loanRequired;
  //                SelectedProperty[name]=checked
  //           })
  //       }
  var Property = Object.keys(loanRequired);

  const handleCheckbox = (e) => {
    loanRequired[e.target.value] = e.target.checked;
    setLoanRequired(loanRequired);
    console.log(loanRequired);
  };
  const onChangeHomeLoan = (e, checkData) => {
    // loanRequired[e.target.value]=e.target.checked;
    setLoanRequired((initialState) => ({
      homeloan: !initialState.homeloan,
      loanAgainstProperty: initialState.loanAgainstProperty,
      commercialProperty: initialState.commercialProperty,
      personalLoan: initialState.personalLoan,
    }));
    // setLoanRequired(loanRequired)
    console.log(checkData);
  };
  const onChangeLoanAgainstProperty = (e, checkData) => {
    // loanRequired[e.target.value]=e.target.checked;
    setLoanRequired((initialState) => ({
      loanAgainstProperty: !initialState.loanAgainstProperty,
      homeloan: initialState.homeloan,
      commercialProperty: initialState.commercialProperty,
      personalLoan: initialState.personalLoan,
    }));
    // setLoanRequired(loanRequired)
    console.log(checkData);
  };
  const onChangeCommercialProperty = () => {
    setLoanRequired((initialState) => ({
      commercialProperty: !initialState.commercialProperty,
      loanAgainstProperty: initialState.loanAgainstProperty,
      homeloan: initialState.homeloan,
      personalLoan: initialState.personalLoan,
    }));
  };
  const onChangePersonalLoan = () => {
    setLoanRequired((initialState) => ({
      personalLoan: !initialState.personalLoan,
      commercialProperty: initialState.commercialProperty,
      loanAgainstProperty: initialState.loanAgainstProperty,
      homeloan: initialState.homeloan,
    }));
  };
  const onChangeResidental = () => {
    setPropertyType((initialState) => ({
      residental: !initialState.residental,
      commercial: initialState.commercial,
    }));
  };
  const onChangeCommericial = () => {
    setPropertyType((initialState) => ({
      commercial: !initialState.commercial,
      residental: initialState.residental,
    }));
  };
  const onChangeUnderConstruction = () => {
    setConstruction((initialState) => ({
      underconstruction: !initialState.underconstruction,
      resale: initialState.resale,
      ready: initialState.ready,
      owned: initialState.owned,
    }));
  };
  const onChangeReady = () => {
    setConstruction((initialState) => ({
      underconstruction: initialState.underconstruction,
      resale: initialState.resale,
      ready: !initialState.ready,
      owned: initialState.owned,
    }));
  };
  const onChangeResale = () => {
    setConstruction((initialState) => ({
      underconstruction: initialState.underconstruction,
      resale: !initialState.resale,
      ready: initialState.ready,
      owned: initialState.owned,
    }));
  };
  const onChangeOwned = () => {
    setConstruction((initialState) => ({
      underconstruction: initialState.underconstruction,
      resale: initialState.resale,
      ready: initialState.ready,
      owned: !initialState.owned,
    }));
  };
  const clickHereOpener=(val)=>{
    setloanName(val)
    setName("")
    setEmail("")
    setContactNo("")
    setDesignation("")
    setNetMonthlySalary("")
    setEmployeeWithTheCompanySince("")
    setPropertyType({
      residental: false,
      commercial: false,
    })
  }
  return (
    <>
      <hr className="container-fluid abouthr d-none d-lg-block"></hr>
      <div className="productimg img-fluid d-md-block">
        <div className="container text-left  producthearderset">
          <h2 className="container display-5 font-weight-bold font-weight-bold">
            MYFIN LOANS FOR{" "}
          </h2>
          <h1 className="container display-5 font-weight-bold">
            IMMEDIATE MONEY{" "}
          </h1>
        </div>
      </div>
      <div className="productimg1 img-fluid d-lg-none d-md-none">
        <div className="container text-left  producthearderset">
          <h2 className="container display-5 font-weight-bold font-weight-bold">
            MYFIN LOANS FOR{" "}
          </h2>
          <h1 className="container display-5 font-weight-bold">
            IMMEDIATE MONEY{" "}
          </h1>
        </div>
      </div>
      {/* <div className="traingleproduct d-none d-lg-block"></div> */}

      <div className="container mt-5 pt-2 otherloanpara">
        <h3 className="text-lg-left text-md-left font-weight-bold">
          <span>OTHER </span>LOANS
        </h3>
        <h5 className="text-lg-left text-md-left pt-4">
          Myfin, currently, does not offer any other type of loans on its own.
          However, Myfin can introduce you to the large spectrum of banks /
          financial institutions and help you get the loan you desire.
        </h5>
        <h5 className="text-lg-left text-md-left pt-3">
          Owing to the wide reach across banks / financial institutions, Myfin
          will refer your case only to those banks / financial institutions
          whose parameters match with your requirements.
        </h5>
      </div>

      <section className="container-fluid accuracyimg p-5 mt-5 ">
        <h3 className="text-center font-weight-bold accuracy ">
          This will ensure, with almost 100% accuracy, that you will get:
        </h3>
        <div className="row  p-lg-5 ">
          <div className="col-lg-3 col-md-6 col-6 col-sm-6 mt-4 mt-lg-0 ">
            <img src="ASSETS/Group 177.png" alt="" />
          </div>
          <div className="col-lg-3 col-md-6 col-6 col-sm-6 mt-4 mt-lg-0 ">
            <img src="ASSETS/Group 175.png" alt="" />
          </div>
          <div className="col-lg-3 col-md-6 col-6 col-sm-6 mt-3 mt-lg-0 ">
            <img src="ASSETS/Group 173.png" alt="" />
          </div>
          <div className="col-lg-3 col-md-6 col-6 col-sm-6 mt-3 mt-lg-0 ">
            <img src="ASSETS/Group 178.png" alt="" />
          </div>
        </div>
      </section>

      <section className="container-fluid loancards  pb-5">
        <div className="ensureheadingdiv ">
          <h3 className="text-center text-lg-left text-md-lett font-weight-bold pt-5 pb-3 ensureheading container">
            This will also ensure that you:
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
            <img
              src="ASSETS/icon.png"
              alt=""
              className="ml-xl-5 ml-lg-3 pl-lg-3 ml-2 ml-md-4 bullet"
            />
          </div>
          <div className="col-lg-11 col-md-11 col-sm-11 col-10 pl-4  ">
            <h4 className="text-left">
              Don’t get confused as to which bank / financial institution to
              approach since all of them speak the same.
            </h4>
            <img
              src="ASSETS/Line 5.png"
              alt=""
              className="img-fluid line d-md-block"
            />
            <hr className="loancardhr d-lg-none d-md-none d-block" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-1">
            <img
              src="ASSETS/icon.png"
              alt=""
              className="ml-xl-5 ml-lg-3 pl-lg-3 ml-2 ml-md-4 bullet"
            />
          </div>
          <div className="col-lg-11 col-md-11 col-sm-11 col-10 pl-4 ">
            <h4 className="text-left">
              Save time, effort and money by being able to reach out to the
              right bank / financial institution for you.
            </h4>
            <img
              src="ASSETS/Line 5.png"
              alt=""
              className="img-fluid line d-md-block"
            />
            <hr className="loancardhr d-lg-none d-md-none d-block" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-1">
            <img
              src="ASSETS/icon.png"
              alt=""
              className="ml-xl-5 ml-lg-3 pl-lg-3 ml-2 ml-md-4 bullet"
            />
          </div>
          <div className="col-lg-11 col-md-11 col-sm-11 col-10 pl-4 ">
            <h4 className="text-left">
              Don’t have to worry about if the bank / financial institution that
              you have approached will be able <br />
              to offer you the best possible deal.
            </h4>
          </div>
        </div>
        <h3 className="text-center font-weight-bold p-5 mt-5 cardheading">
          Myfin can help you in getting any of the following types of loans:
        </h3>
        <div className="container">
          <div className="card ">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="ASSETS/home-loan.png"
                  alt=""
                  className="img-fluid homeloan"
                />
              </div>
              <div className="col-lg-8 pt-3">
                <h5 className="card-title mt-2 text-lg-left text-md-left font-weight-bold ml-3 ml-lg-0 mr-2 mr-lg-0 homelonaheading">
                  Home Loans
                </h5>
                <h5 className="text-lg-left text-md-left font-weight-bold pt-2 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  These are loans that you avail of when you want to buy a
                  house. The house could be under construction, ready or a
                  resale house.
                </h5>
                <button
                  className=" btn btn-md click text-white d-lg-block  mt-lg-4 mt-2 "
                  data-toggle="modal"
                  data-target="#formModalCenter"
                  onClick={()=>clickHereOpener("HOME  LOAN")}
                >
                  CLICK HERE
                </button>
                <h5 className="text-lg-left text-md-left pt-4 ml-2 ml-lg-0 mr-2 mr-lg-0 pb-3">
                  Fill in some of your details and we will refer you to the
                  banks / financial institutions to get you this loan
                </h5>
              </div>
            </div>
          </div>

          <div className="card mt-5">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="ASSETS/loan_property.png"
                  alt=""
                  className="img-fluid loanproperty"
                />
              </div>
              <div className="col-lg-8 pt-3">
                <h5 className="card-title mt-2 text-lg-left text-md-left font-weight-bold ml-3 ml-lg-0 mr-2 mr-lg-0">
                  Loans Against Property
                </h5>
                <h5 className="text-lg-left text-md-left font-weight-bold pt-2 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  These are loans that you take against your existing
                  residential / commercial property. These are longer term loans
                  and can be used to meet your long-term funding requirements –
                  be it for personal or business use. The end use cannot be
                  illegal or speculative in nature as defined by the statutes in
                  India.
                </h5>
                <button
                  className=" btn btn-md click text-white d-lg-block mt-lg-4 mt-2"
                  data-toggle="modal"
                  data-target="#formModalCenter"
                    onClick={()=>clickHereOpener("LOAN AGAINST PROPERTY")}
                >
                  CLICK HERE
                </button>
                <h5 className="text-lg-left text-md-left pt-3 ml-2 ml-lg-0 mr-2 mr-lg-0 pb-3">
                  Fill in your details and we will help you get to the banks /
                  financial institutions
                </h5>
              </div>
            </div>
          </div>

          <div className="card mt-5">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="ASSETS/commercial.png"
                  alt=""
                  className="img-fluid commercial"
                />
              </div>
              <div className="col-lg-8 pt-3">
                <h5 className="card-title mt-2 text-lg-left text-md-left font-weight-bold ml-3 ml-lg-0 mr-2 mr-lg-0">
                  Loans to buy commercial property
                </h5>
                <h5 className="text-lg-left text-md-left font-weight-bold pt-2 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  {" "}
                  These are loans that you avail of when you want to buy a
                  commercial office / industrial unit / office gala / shop. The
                  property could be under construction, ready or a resale
                  property.
                </h5>

                <button
                  className=" btn btn-md click text-white d-lg-block mt-lg-4 mt-2"
                  data-toggle="modal"
                  data-target="#formModalCenter"
                  onClick={()=>clickHereOpener("LOAN TO BUY COMMERCIAL PROPERTY")}
                >
                  CLICK HERE
                </button>
                <h5 className="text-lg-left text-md-left pt-3 pb-3 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  Fill in your details and we will do the rest
                </h5>
              </div>
            </div>
          </div>

          <div className="card mt-5">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="ASSETS/personal_loan.png"
                  alt=""
                  className="img-fluid personalloan"
                />
              </div>
              <div className="col-lg-8 pt-3">
                <h5 className="card-title mt-2 text-lg-left text-md-left font-weight-bold ml-3 ml-lg-0 mr-2 mr-lg-0">
                  Personal Loans
                </h5>
                <h5 className="text-lg-left text-md-left font-weight-bold pt-2 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  These are longer term unsecured loans that are offered to meet
                  your medium-term funding requirements. These types of loans
                  are usually availed of to meet education fee needs of yourself
                  / your children, to pay for marriage expenses, etc. The
                  typical tenure of these loans ranges from 3 years to a maximum
                  of 7 years.
                </h5>
                <button
                  className=" btn btn-md click text-white d-lg-block mt-lg-4 mt-2"
                  data-toggle="modal"
                  data-target="#formModalCenter"
                  onClick={()=>clickHereOpener("PERSONAL LOAN")}
                >
                  CLICK HERE
                </button>
                <h5 className="text-lg-left text-md-left pt-3 pb-3 ml-2 ml-lg-0 mr-2 mr-lg-0">
                  We will refer you to the banks / financial institutions
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="container-fluid otherloanhr d-none d-lg-block"></hr>
      <div
        className="modal fade onloanmodal modal-scrollable"
        id="formModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        //aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div class="modal-body">
              <div className="container-fluid modal-body ml-3">
                <h4
                  className="modal-title  font-weight-bold pt-4"
                  id="exampleModalLongTitle "
                >
                  APPLICATION FORM - {(loanName)}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="ASSETS/cancel.png" alt="" />
                </button>
                <form
                  className="container-fluid"
                  onSubmit={OtherLoanFormHandler}
                >
                  <div>
                    <div className="text-center mb-2 mt-2">
                      <h5 className="font-weight-bold">PERSONAL DETAILS</h5>
                    </div>
                    <div className="form-group row">
                      <label
                        for="colFormLabelName"
                        className="col-sm-3  float-left col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="colFormLabelName"
                          placeholder="Name"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="colFormLabel"
                        class="col-sm-3 float-left col-form-label"
                      >
                        Email Id
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="email"
                          class="form-control"
                          id="colFormLabel"
                          placeholder="Email Id"
                          onChange={emailhandleChnage}
                          value={email}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="colFormLabelNameNumber"
                        className="col-sm-3 float-left col-form-label"
                      >
                        Contact Number
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="colFormLabelNameNumber"
                          placeholder="Contact Number"
                          value={contactNo}
                          onChange={mobilehandleChnage}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {/**Employment Details */}
                  <div>
                    <div className="text-center mb-2 mt-2">
                      <h5 className="font-weight-bold">EMPLOYMENT DETAILS</h5>
                    </div>
                    <div className="form-group row">
                      <label
                        for="colFormLabelDesignation"
                        className="col-sm-3  float-left col-form-label"
                      >
                        Designation
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="colFormLabelDesignation"
                          placeholder="Designation"
                          required
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="colFormLabelEmployment"
                        class="col-sm-3 float-left col-form-label"
                      >
                        Employment Since
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="date"
                          class="form-control"
                          id="colFormLabelEmployment"
                          placeholder="Employment Since"
                          onChange={(e) =>
                            setEmployeeWithTheCompanySince(e.target.value)
                          }
                          value={employedWithTheCompanySince}
                          max={today}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        for="colFormLabelNameNet"
                        className="col-sm-3 float-left col-form-label"
                      >
                        Net Monthly Salary
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="colFormLabelNameNet"
                          placeholder=" Net Monthly Salary"
                          value={netMonthlySalary}
                          onChange={(e) => setNetMonthlySalary(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                   {/**Property Details */}
                   <div>
                    <div className="text-center mb-2 mt-2">
                      <h5 className="font-weight-bold">PROPERTY DETAILS</h5>
                    </div>
                    <div className="form-group row">
                      <label
                        for="colFormLabelProperty"
                        className="col-sm-3  float-left col-form-label"
                      >
                        Property Location
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="colFormLabelProperty"
                          placeholder="Property Location"
                          required
                          value={propertyLocation}
                         onChange={(e) => setPropertyLocstion(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        for="colFormLabelPropertyType"
                        class="col-sm-3 float-left col-form-label"
                      >
                        Property Type
                      </label>
                      <div class="col-sm-8">
                  <label className="float-left text-left ml-1 pt-2 checkmark1div">
                    Residential-
                    <input
                      type="radio"
                      onChange={onChangeResidental}
                      checked={propertyType.residental}
                      className="d-none form-control"
                      id="colFormLabelProperty"
                      name="inlineRadioOptions"    
                      required      
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left ml-5 pt-2 checkmark1div">
                    Commercial-
                    <input
                      type="radio"
                      onChange={onChangeCommericial}
                      checked={propertyType.commercial}
                      className="d-none form-control"
                      id="colFormLabelProperty"
                      name="inlineRadioOptions"
                      required
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                    </div>
                    
                    <div className="form-group row">
                      <label
                        for="carperAreaProperty"
                        className="col-sm-3 float-left col-form-label"
                      >
                        Property (sq.ft.)
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="carperAreaProperty"
                          placeholder="Property (sq.ft.)"
                          name="carperAreaProperty"
                          value={carpetAreaProperty}
                          onChange={(e) => setCarpetAreaProperty(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <button
                      className="btn btn-primary submitButton"
                      //onClick={OtherLoanForm}
                      type="submit"
                    >
                      SUBMIT
                    </button>
                    {/* <div class="form-group row">
                      <div className="col-sm-3">
                      <label
                        for="colFormLabelPropertyType"
                        class="float-left col-form-label"
                      >
                        Stage of Construction
                      </label>
                      </div>
                      <div class="col-sm-8">
                      <div class="form-group row">
                  <label className="float-left text-left ml-1 pt-2 checkmark1div">
                  Under Construction-
                    <input
                      type="radio"
                      onChange={onChangeResidental}
                      checked={propertyType.residental}
                      className="d-none"
                      id="colFormLabelProperty1"
                      name="inlineRadioOptions1"          
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left ml-5 pt-2 checkmark1div">
                  Ready-
                    <input
                      type="radio"
                      onChange={onChangeReady}
                      checked={construction.ready}
                      className="d-none ml-2"
                      id="colFormLabelProperty1"
                      name="inlineRadioOptions1"
                    />
                    <span className="checkmark"></span>
                  </label>
                  </div>
                  <div class="form-group row">
                  <label className="float-left text-left ml-1 pt-2 checkmark1div">
                  Resale-
                    <input
                      type="radio"
                      onChange={onChangeResale}
                      checked={construction.resale}
                      className="d-none "
                      id="colFormLabelProperty1"
                      name="inlineRadioOptions1"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left ml-5 pt-2 checkmark1div">
                  Owned-
                    <input
                      type="radio"
                      onChange={onChangeResale}
                      checked={construction.resale}
                      className="d-none "
                      id="colFormLabelProperty1"
                      name="inlineRadioOptions1"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                    </div>
                    </div> */}
                  {/* <div className="row justify-content-center">
                    <button
                      className="btn btn-md apply3 text-white "
                      //onClick={OtherLoanForm}
                      type="submit"
                    >
                      SUBMIT
                    </button>
                        </div>*/}
                  {/*<div>
                <div className="mb-2">
                <h5 className="text-center font-weight-bold mt-5 mb-2">
                  EMPLOYMENT DETAILS
                </h5>
                <div className="label1 mt-3">
                  <label className="text-left float-left pl-4 pt-2">
                    Designation:
                  </label>
                  <br className="d-lg-none d-md-none" />
                  <br className="d-lg-none d-md-none" />
                  <input
                    type="text"
                    className="w-75 border-none float-lg-right float-md-right float-left no-border pl-2"
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
                <br className="d-lg-none d-md-none" />
                <div className="label1 mt-3">
                  <label className="text-left float-left pl-4 pt-2">
                    Employemnt Since:
                  </label>
                  <br className="d-lg-none d-md-none" />
                  <br className="d-lg-none d-md-none" />
                  <input
                    type="date"
                    className="w-75 border-none float-lg-right float-md-right float-left no-border pl-2"
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
                <br className="d-lg-none d-md-none" />
              
                <div className="label1 mt-3">
                  <label className="float-left text-left pl-4 pt-2">
                    Net Monthly Salary:
                  </label>
                  <br className="d-lg-none d-md-none" />
                  <input
                    type="text"
                    className="w-75 border-none float-lg-right float-md-right float-left no-border pl-2"
                    name="netMonthlySalary"
                    value={netMonthlySalary}
                    onChange={(e) => setNetMonthlySalary(e.target.value)}
                  />
                </div>
                <br className="d-lg-none d-md-none" />
                <div className="label1 mt-3">
                  <label className="float-left text-left pl-4 pt-2">
                    Other Loan EMI:
                  </label>
                  <br className="d-lg-none d-md-none" />
                  <input
                    type="text"
                    className="w-75 border-none float-lg-right float-md-right float-left no-border pl-2"
                    name="netMonthlySalary"
                    value={netMonthlySalary}
                    onChange={(e) => setNetMonthlySalary(e.target.value)}
                  />
                </div>
               </div>
               </div>
                {/* <div className="mt-5">
                <h5 className="text-center font-weight-bold  mb-2">
                  LOAN REQUIRED: (Kindly check the relevant box)
                </h5>
                <div className="container-fluid label2 mt-4">
                  <label className="container text-left pl-5 font-weight-bold checkmarkdiv">
                    Home Loans
                    <input
                      type="checkbox"
                      onChange={onChangeHomeLoan}
                      checked={loanRequired.homeloan}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container text-left pl-5 font-weight-bold checkmarkdiv">
                    Loans Against Property
                    <input
                      type="checkbox"
                  
                      onChange={onChangeLoanAgainstProperty}
                      checked={loanRequired.loanAgainstProperty}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container text-left pl-5 font-weight-bold checkmarkdiv">
                    Loans to buy commercial property
                    <input
                      type="checkbox"
                      onChange={onChangeCommercialProperty}
                      checked={loanRequired.commercialProperty}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container text-left pl-5 font-weight-bold checkmarkdiv">
                    Personal Loans
                    <input
                      type="checkbox"

                      onChange={onChangePersonalLoan}
                      checked={loanRequired.personalLoan}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                </div> */}
                  {/* {Property} */}
                  {/*<h5 className="text-center font-weight-bold mt-5 mb-2">
                  PROPERTY DETAILS: (If applicable)
                </h5>
                <div className="label1 mt-3 property">
                  <label className="text-left float-left pl-4 pt-2 ">
                    Property Location :
                  </label>
                  <input
                    type="text"
                    className="border-none ml-1 no-border pl-2 d-none"
                    name="propertyLocation"
                    value={propertyLocation}
                    onChange={(e) => setPropertyLocstion(e.target.value)}
                   
                  />
                </div>
                <div className="label1 mt-3 ">
                  <label className="text-left float-left pl-4 pt-2">
                    Property Type :
                  </label>
                  <label className="float-left text-left pl-5 ml-4 pt-2 checkmark1div">
                    Residential
                    <input
                      type="checkbox"
                      onChange={onChangeResidental}
                      checked={propertyType.residental}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left pl-4 ml-3 pt-2 checkmark1div">
                    Commercial
                    <input
                      type="checkbox"
                      onChange={onChangeCommericial}
                      checked={propertyType.commercial}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="label1 mt-3 checkmark1div">
                  <label className="text-left float-left pl-4 pt-2">
                    Stage of Construction :
                  </label>
                  <label className="float-left text-left pl-3 pt-2">
                    Under Construction
                    <input
                      type="checkbox"
                      onChange={onChangeUnderConstruction}
                      checked={construction.underconstruction}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left pl-4 ml-2 pt-2">
                    Ready
                    <input
                      type="checkbox"
                      onChange={onChangeReady}
                      checked={construction.ready}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left pl-4 pt-2 ml-2">
                    Resale
                    <input
                      type="checkbox"
                      onChange={onChangeResale}
                      checked={construction.resale}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="float-left text-left pl-4 pt-2 ml-2">
                    Owned
                    <input
                      type="checkbox"
                      onChange={onChangeOwned}
                      checked={construction.owned}
                      className="d-none"
                    />
                    <span className="checkmark ml-2"></span>
                  </label>
                </div>
                <div className="label1 mt-3 carpet">
                  <label className="text-left float-left pl-4 mb-5 ">
                    Carpet Area of <br />
                    Property (sq.ft.) :
                  </label>
                  <input
                    type="text"
                    className="border-none ml-4 no-border pl-2"
                    name="carperAreaProperty"
                    value={carpetAreaProperty}
                    onChange={(e) => setCarpetAreaProperty(e.target.value)}
                  />
                </div>
                <div className="label1 mt-4 mb-5 estimate">
                  <label className="float-left text-left pb-5 pl-4 netmonth">
                    Estimated Market <br />
                    Value of property <br />
                    (lakhs) :
                  </label>
                  <input
                    type="text"
                    className="border-none ml-3 no-border pl-2"
                    name="estimateMarket"
                    value={estimateMarket}
                    onChange={(e) => setEstimateMarket(e.target.value)}
                  />
              </div>*/}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="site-footer ml-lg-5">
        <div className="container ">
          <div className="row ml-lg-2">
            <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
              <div className="imgdiv mb-4">
                <img
                  src="ASSETS/Group 234.png"
                  className="order-2 order-lg-1 mr-lg-0 "
                  alt=""
                />
              </div>

              <p className="text-lg-left text-center text-md-left">
                Myfin - A simple 3 step online process to raise <br /> immediate
                money between 2 salary cycles. <br /> Everyone, very often,
                faces with a sudden <br /> requirement of funds.
              </p>
              <h5 className="text-dark text-lg-left text-md-left  mt-4">
                These could be for any of the following:
              </h5>
              <p className="text-dark text-lg-left text-md-left pb-2 pay">
                <span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br />
                <span>◉</span>&nbsp;&nbsp;Make Credit card payments
              </p>

              <a href="#concepts">
                {" "}
                <button className="btn btn-md apply3 text-white">
                  KNOW MORE
                </button>
              </a>
            </div>
            <div className="vertical-line d-none d-sm-block"></div>
            <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
              <h6 className="font-weight-bold d-none d-sm-block">
                QUICK LINKS
              </h6>
              <ul className="footer-links d-none d-sm-block mt-lg-4">
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <a href="#team">Team</a>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">How we work</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Products</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Contact Us</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Privacy Policy</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Terms & Conditions</NavLink>
                </li>
              </ul>
            </div>
            <div className="vertical-line d-none d-sm-block"></div>
            <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
              <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
              <ul className="footer-links d-none d-sm-block mt-lg-4">
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Salaried</NavLink>
                </li>
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Business</NavLink>
                </li>
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Professional</NavLink>
                </li>
              </ul>

              <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">
                CONNECT WITH US
              </h6>
              <div className="row mt-3">
                <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg"></div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                  <img src="ASSETS/Group 79.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                  <img src="ASSETS/Group 80.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                  <img src="ASSETS/Group 61.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                  <img src="ASSETS/Group 63.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
      <div className="container-fluid lastfooter">
        <div className="row ">
          <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
            <p className="copyright-text text-white pt-2">
              All rights reserved. | Privacy Policy. | Copyright with Fiscus
              Services Private Limited.
            </p>
          </div>

          <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
            <p className="copyright-text text-white pt-2">
              Developed by : April Innovations
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br className="d-lg-none d-md-none d-block" />
              Designed by : Beak Media
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otherloan;
