import React from "react";
import "./navbar.css";
import { NavLink, useHistory } from "react-router-dom";

const Navbar = (props) => {
  const currentRoute = useHistory().location.pathname.toLowerCase();
  const history = useHistory();
  const homeHandler = () => {
    history.push("/home");
  };
  return (
    <>
      <div className="navbarHr ">
        <img
          src="ASSETS/HEADER.png"
          alt=""
          className="img-fluid w-100 d-none  d-lg-block   "
        />

        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container  logo">
            <div
              className="collapse navbar-collapse order-lg-0 order-3 "
              id="collapseNavbar"
            >
              <ul className="navbar-nav mr-auto justify-content-end">
                {/* <li className="nav-item">
                            <NavLink to="/home" className="nav-link text-dark">HOME</NavLink>
                        </li> */}

                <li
                  className={
                    currentRoute.includes("about")
                      ? "nav-item active "
                      : "nav-item"
                  }
                >
                  <NavLink to="/About" className="nav-link text-dark">
                    ABOUT US
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    to="/product"
                    className="nav-link text-dark dropdown-toggle"
                    id="navbarDropdownLink"
                    data-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                    aria-haspopup="true" 
                  >
                    PRODUCTS
                  </NavLink>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <NavLink
                      to="/product"
                      className="dropdown-item"
                      data-toggle="collapse"
                      data-target="#exNavbar"
                    >
                      Myfin loans for immediate money
                    </NavLink>

                    <NavLink
                      to="/otherloan"
                      className="dropdown-item "
                      data-toggle="collapse"
                      data-target="#exNavbar"
                    >
                      Other loans
                    </NavLink>
                  </div>
                </li>

                
                <li
                  className={
                    currentRoute.includes("corporate")
                      ? "nav-item active "
                      : "nav-item"
                  }
                >
                  <NavLink
                    to="/corporate"
                    className="nav-link text-dark mr-lg-5 "
                  >
                    CORPORATES
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* <button className="ml-auto order-1">Login</button> */}

            {/* <img src="ASSETS/Group 124.jpg" className="order-1 order-lg-1 mr-lg-0 " alt=""/> */}

            {/* <div className="headerlogo "> */}
            {/* <img src="images/logo.jpg" className="order-lg-1 d-lg-block " alt=""/>
                    <h1 className="font-weight-bold order-lg-1  ml-lg- mr-5 d-lg-block ">myfin.in</h1>  */}

            {/* </div> */}
            {/* <NavLink to="/Apply"><button className="btn btn-md apply1 rounded-pill order-10 text-white ml-auto">LOGIN</button></NavLink> */}
            <button
              className="navbar-toggler mr-auto "
              type="button"
              data-toggle="collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              aria-controls="navbarSupportedContent"
              data-target="#collapseNavbar"
            >
              <span className="navbar-toggler-icon "></span>
            </button>

            <img
              role="button"
              src="ASSETS/Group 234.png"
              className="order-lg-1 mr-lg-0 "
              alt=""
              onClick={homeHandler}
            />
            <NavLink to="/Apply">
              <button className="btn btn-md apply1 rounded-pill text-white   d-lg-none d-block">
                LOGIN
              </button>
            </NavLink>
            <div
              className="collapse navbar-collapse order-lg-3 order-4"
              id="collapseNavbar"
            >
              <ul className="navbar-nav ml-auto ">
                {/* <li className="nav-item ">
                            <NavLink to="/" className="nav-link text-dark">Home</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink to="/About" className="nav-link text-dark">About</NavLink>
                        </li> */}
                <li className="nav-item">
                  <NavLink to="/associate" className="nav-link text-dark ml-3">
                    ASSOCIATES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/Faq" className="nav-link text-dark">
                    FAQS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contactus" className="nav-link text-dark">
                    CONTACT US
                  </NavLink>
                </li>
                {/* <li>
                             <NavLink to="/Login"> <button className="btn btn-lg rounded-pill login text-white">Login</button></NavLink>
                        </li>  */}
                <li>
                  <NavLink to="/">
                    <button className="btn btn-md apply1 rounded-pill text-white  ml-lg-4 d-lg-block d-md-none d-none" onClick={()=> window.open('https://user.myfin.in/#/login', '_blank', 'noopener,noreferrer')}>
                      LOGIN
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/*] <hr className="container-fluid abouthr d-none d-lg-block"></hr> */}
    </>
  );
};

export default Navbar;
