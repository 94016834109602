import React,{useState} from 'react'
import './Product.css'
import { NavLink} from 'react-router-dom'

const Product=() =>{
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [color,setColor]=useState('	#696969');
  const [color1, setColor1] = useState('#696969')
  

    return (
       <>
         <hr className="container-fluid abouthr d-none d-lg-block"></hr>
<div className="productimg img-fluid d-md-block">

<div className="container text-left  producthearderset">
<h2 className="container display-5 font-weight-bold font-weight-bold">MYFIN LOANS FOR  </h2>
                    <h1 className="container display-5 font-weight-bold" >IMMEDIATE MONEY </h1>
                    </div>
</div>
{/* <div className="traingleproduct d-none d-lg-block"></div> */}
<div className="productimg1 img-fluid d-lg-none d-md-none">
<div className="container text-left  producthearderset">
<h2 className="container display-5 font-weight-bold font-weight-bold">MYFIN LOANS FOR  </h2>
                    <h1 className="container display-5 font-weight-bold" >IMMEDIATE MONEY </h1>
                    <h4 className="p-3 text-left font-weight-bold ml-lg-4">The fastest, easiest way to raise funds when you need money immediately!</h4>
        </div>
</div>
       


        <section className="immediate1img img-fluid img-responsive ">
            <div className="layer1 ">
              
            <div className="container-fluid immediate_needs1 need1">
           
        
                <div className="row pt-lg-5 pt-4">
                    <div className="col-lg-8 col-md-7 col-12 col-sm-12 pt-1 ml-lg-4 ">
                    <h4 className="p-3 text-left font-weight-bold ml-lg-4 d-lg-block d-md-block  d-none">The fastest, easiest way to raise funds when you need money immediately!<br/> Are you facing any of the following situations and you need money NOW?</h4>
                                         <h4 className="p-3 text-left font-weight-bold ml-lg-4 d-block d-md-none  d-lg-none">Are you facing any of the following situations and you need money NOW?</h4>
                      <div className="row pt-4 pt-lg-0">
                     
                          <div className="col-lg-5 col-md-6 col-12 col-sm-12 ml-lg-5 p-3 p-lg-1 needs pl-4  pl-lg-2">
                          <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Pay Unforeseen Medical Bills</h6>
                                    </div>
                                </div>
                               
                          <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Make Credit card payments</h6>
                                    </div>
                                </div>
                              
                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Pay Children's school fees</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Go on a family holiday</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-11 col-10">
                                <h6 className=" text-left font-weight-bold p-1">Rent or deposit payment</h6>
                                    </div>
                                </div>

                         
                         
                          </div>
                          
                          <div className="col-lg-6 col-md-6 col-12 col-sm-12  p-3 p-lg-1 needs pl-4 pl-lg-2 ml-lg-0">
                          <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1 ">Sudden vehicle payment</h6>
                                    </div>
                                </div>
  

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Pay your EMIs on time</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Celebrate festivals with family</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Purchase household appliances</h6>
                                    </div>
                                </div>

                                <div className='row needsrow pl-1 pl-lg-0 pl-md-0'>
                             <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <img src="ASSETS/icon.png" alt="" className="ml-lg-0 ml-2 ml-md-5"/>
                                </div>
                                <div className="col-lg-9 col-xl-11 col-md-10 col-sm-11 col-10">
                             <h6 className=" text-left  mt-lg-0 font-weight-bold p-1">Gift someone on their wedding/birthday</h6>
                                    </div>
                                </div>
                            
                              </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12 col-sm-12 meetheading1">
                    <h5 className="p-3 text-left ml-lg-2 font-weight-bold ">ANY OTHER REASON...</h5>
                    </div>
                    </div>
                    
                   
                    <div className="col-lg-3 col-12 col-md-4 col-sm-12 money3  half-circle d-none d-md-block mr-lg-5">
                        <img src="ASSETS/money.jpg" alt="" />
                        </div>
                        <div className="col-lg-2 col-12 col-md-2 col-sm-12 money2 half-circle d-md-none ">
                        <img src="ASSETS/money2.png" alt="" className="img-fluid" />
                        </div>
                </div>
                </div>
               
                </div>
            </section>

            <section className="container-fluid p-3 solution ">
            <div className="loanimg img-fluid pt-5 pb-5">
            <div className="row mb-5 ">
                <div className="col-sm-12">
                    <h3 className="text-center font-weight-bold pt-5" id="team"><span>MYFIN </span>IS THE SOLUTION</h3>
                </div>
               
            </div>
            <div className="ml-lg-5  row  mr-lg-5">
                <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-lg-3 ">
                <img src="ASSETS/Ellipse 7.svg" alt=""></img>
                 <h6 className="mt-lg-3 mt-3 font-weight-bold ">IMMEDIATE LOANS</h6>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-lg-3 ">
                    <img src="ASSETS/Group 164.svg" alt=""></img>
                     <h6 className="mt-lg-3 mt-3 font-weight-bold ">SIMPLE 3-STEP <br/>PROCESS</h6>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 mt-md-0 mt-lg-3 ">
                    <img src="ASSETS/Group 162.svg" alt=""></img>
                      <h6 className="mt-lg-3 mt-3 font-weight-bold ">NO SECURITY <br/> REQUIRED</h6>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 mt-lg-3 ">
                    <img src="ASSETS/Group 168.svg" alt=""></img>
                     <h6 className="mt-lg-3 mt-3 font-weight-bold ">NO GUARANTORS REQUIRED</h6>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 mt-lg-3 ">
                    <img src="ASSETS/Group 165.svg" alt=""></img>
                           <h6 className="mt-lg-3 mt-3 font-weight-bold ">QUICK AND IMMEDIATE</h6>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 mt-lg-3 ">
                    <img src="ASSETS/Group 166.svg" alt=""></img>
                            <h6 className="mt-lg-3 mt-3 font-weight-bold ">STRESS-FREE</h6>
                    </div>
            </div>
            </div>
            </section>
           
            <section className="container-fluid monthcard ">
            <h4 className="text-center font-weight-bold pt-5" >Select from any of our 2 product variants</h4>
            <h4 className="text-center font-weight-bold monthcard_heading"> designed specifically to take care of your cash flows</h4>   
            
            <div className="container mb-5 mt-5 d-lg-block d-md-block d-none">
                <div className="row mx-0 months card-deck flex-column flex-lg-row flex-md-row mb-3">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0 price">
                        <div className="card card-pricing  text-center px-3 mb-4">
                        
                            <div className="bg-transparent card-header pt-4 border-0">
                                {/* <h1 className="h1 front-wight-normal text-white mb-0">sfd</h1> */}
                            </div>
                            <div className="card-body pt-4 mt-1  pt-lg-5 mt-lg-5 mt-xl-4 pt-xl-5 Tenorrow mt-md-4 pt-md-5">
                                <ul className="list-unstyled mt-xl-1 mt-lg-2 mt-md-3">
                                    <li className="text-left font-weight-bold">Tenor</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">Loan Amount</li><hr className="cardhr  "></hr>
                                    <li className="text-left font-weight-bold my-auto">Maximum Loan Eligibility</li><br/><hr className="cardhr "></hr>
                                    <li className="text-left font-weight-bold">Minimum Employment</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Status of Employment</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">Repayment</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Interest Rate</li><hr className="cardhr pt-2"></hr>
                                    <li className="text-left font-weight-bold">Other Charges - PF & AMC</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Application</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">Foreclosure</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Partial Prepayment</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Penal Interest for Overdue Payments</li><hr className="cardhr"></hr>
                                    <li className="text-left font-weight-bold">Turn Around Time</li>
                                    <hr className="cardhr "></hr>
                                    <li className="text-left  documentation font-weight-bold">Documentation Required</li>
                                    <hr className="cardhr "></hr>
                                    <li className="text-left font-weight-bold">Process</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0 price">
                        <div className="card card-pricing text-center px-3 mb-4">
                            
                            <div className="bg-transparent card-header pt-4 border-0 mb-1">
                                <h5 className="text-white text-center mb-0 container border rounded-pill  p-2">MYFIN 3 MONTHS</h5>
                            </div>
                            <div className="card-body pt-4">
                                <ul className="list-unstyled ">
                                    <li className="text-left">3 months</li><hr className="cardhr"></hr>
                                    <li className="text-left">Rs. 9000 to Rs. 200000</li><hr className="cardhr"></hr>
                                    <li className="text-left">Upto 1 time Net Monthly Take Home Salary</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">6 months</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">Permanent</li><hr className="cardhr"></hr>
                                    <li className="text-left">EMI via Salary Deduction</li><hr className="cardhr"></hr>
                                    <li className="text-left">1.75% per month</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0"><button className=" btn btn-sm click text-white " data-toggle="modal" data-target="#exampleModalCenter">Click here </button></li><hr className="cardhr"></hr>
                                    <li className="text-left">Application Form</li><hr className="cardhr"></hr>
                                    <li className="text-left">2% + GST  on Outstanding</li><hr className="cardhr"></hr>
                                    <li className="text-left">Not permitted</li><hr className="cardhr"></hr>
                                    <li className="text-left">0.067% for each day of delay</li><br className="d-xl-none d-lg-block d-md-none d-none"/><hr className="cardhr"></hr>
                                    <li className="text-left">2-3 Days</li>
                                    <hr className="cardhr"></hr>
                                    <li className="text-left  ">Aadhaar Card<br/>
                                    Current Address proof – Latest Electricity <br/>
                                    Bill, Telephone Bill, Mobile Bill, Rent <br/> Agreement (if different in Aadhaar)<br/>
                                    Pan Card<br/>
                                    1 month Bank statement (latest) with<br/> salary credit reflection<br/>
                                    Photo<br/>
                                    Latest Salary slip<br/>
                                    1 Security Cheque<br/>
                                    All to be self-attested by Customer
                                    </li>
                                    <hr className="cardhr"></hr>
                                    <li className="text-left">Online</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0 price">
                        <div className="card card-pricing text-center px-3 mb-4">
                           
                            <div className="bg-transparent card-header pt-4 border-0 mb-1">
                            <h5 className="text-white text-center mb-0 container border rounded-pill  p-2 ">MYFIN 6 MONTHS</h5>
                            </div>
                            
                            <div className="card-body pt-4">
                                <ul className="list-unstyled ">
                                    <li className="text-left">6 months</li><hr className="cardhr"></hr>
                                    <li className="text-left">Rs. 9000 to Rs. 200000</li><hr className="cardhr"></hr>
                                    <li className="text-left">Upto 2 times Net Monthly Take Home Salary</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">6 months</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0">Permanent</li><hr className="cardhr"></hr>
                                    <li className="text-left">EMI via Salary Deduction</li><hr className="cardhr"></hr>
                                    <li className="text-left">1.75% per month</li><hr className="cardhr"></hr>
                                    <li className="text-left mb-md-4 pb-md-3 mb-lg-0 pb-lg-0"><button className=" btn btn-sm click text-white " data-toggle="modal" data-target="#exampleModalCenter2">Click here</button></li><hr className="cardhr"></hr>
                                    <li className="text-left">Application Form</li><hr className="cardhr"></hr>
                                    <li className="text-left">2% + GST  on Outstanding</li><hr className="cardhr"></hr>
                                    <li className="text-left">Not permitted</li><hr className="cardhr"></hr>
                                    <li className="text-left">0.067% for each day of delay</li><br className="d-xl-none d-lg-block d-md-none d-none"/><hr className="cardhr"></hr>
                                    <li className="text-left">2-3 Days</li>
                                    <hr className="cardhr"></hr>
                                    <li className="text-left ">Aadhaar Card<br/>
                                    Current Address proof – Latest Electricity <br/>
                                    Bill, Telephone Bill, Mobile Bill, Rent <br/> Agreement (if different in Aadhaar)<br/>
                                    Pan Card<br/>
                                    1 month Bank statement (latest) with<br/> salary credit reflection<br/>
                                    Photo<br/>
                                    Latest Salary slip<br/>
                                    1 Security Cheque<br/>
                                    All to be self-attested by Customer
                                    </li>
                                    <hr className="cardhr"></hr>
                                    <li className="text-left">Online</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            </section>
            <section className="container mt-5 pt-3 d-lg-none d-md-none d-block tablecard">
            <button style={{background:color}} className=" btn btn-md threemonth text-white mt-4" onClick={()=>{setShow(true); /* setShow1(false); */ setColor('#003366')}}>MYFIN - 3 MONTHS</button><br/>
            
            
            <div className="row pt-4 ">
              {show ?
              <div className="col-sm-12 col-12 mb-3">
                <div className="card border-dark">

                  <table className="table">
                    <tbody >
                      <tr >
                        <td className="font-weight-bold text-left  pt-4">Tenor</td>
                        <td className="text-left  pt-4">3 months</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold text-left  pt-2">Loan Amount</td>
                        <td className="text-left  pt-2">Rs. 9000 to Rs. 200000</td>
                      </tr>
                      <tr className="pt-0">
                        <td className="font-weight-bold text-left  pb-2">Maximum Loan Eligibility</td>
                        <td className="text-left  pt-2">Upto 1 time Net Monthly Take Home Salary</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Minimum Employment</td>
                        <td className="text-left  pt-2">6 months</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Status of Employment</td>
                        <td className="text-left  pt-2">Permanent</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Repayment</td>
                        <td className="text-left  pt-2">EMI via Salary Deduction</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Interest Rate</td>
                        <td className="text-left  pt-2">1.75% per month</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Other Charges – PF & AMC</td>
                        <td className="text-left  pt-3"><button className=" btn btn-sm click text-white " data-toggle="modal" data-target="#exampleModalCenter">Click here</button></td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Application</td>
                        <td className="text-left  pt-2">Application Form</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Foreclosure</td>
                        <td className="text-left  pt-2">2% + GST  on Outstanding</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Partial Prepayment</td>
                        <td className="text-left  pt-2">Not permitted</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Penal Interest for Overdue Payments</td>
                        <td className="text-left  pt-2">0.067% for each day of delay</td>
                      </tr>

                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Turn Around Time</td>
                        <td className="text-left  pt-2">2-3 Days</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Documentation required</td>
                        <td className="text-left  pt-2">Aadhaar Card <br/>
                        Current Address proof – Latest Electricity Bill, Telephone Bill, Mobile Bill, Rent Agreement (if different in Aadhaar)<br/>
                        Pan Card <br/>
                        1 month Bank statement (latest) with salary credit reflection <br/>
                        Photo <br/>
                        Latest Salary slip <br/>
                        1 Security Cheque <br/>
                        All to be self-attested by Customer</td>
                        
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2 pb-3">Process</td>
                        <td className="text-left pt-2">Online</td>
                      </tr>
                    </tbody>
                  </table>
                  
                  {/* <div className="row">
                    <div className="col-sm-6 col-6">
                     <div className="card-body ">
                                   <h6 className="text-left font-weight-bold pt-2">Tenor</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Loan Amount</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Maximum Loan Eligibility</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Minimum Employment</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Status of Employment</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Repayment</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Interest Rate</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Other Charges - PF & AMC</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Application</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Foreclosure</h6>
                                    <h6 className="text-left font-weight-bold pt-4">Partial Prepayment</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Penal Interest for Overdue Payments</h6>
                                    <h6 className="text-left font-weight-bold pt-2">Turn Around Time</h6>
                        
                       </div>
                    </div>
                    <div className="col-sm-6 col-6">
                    <div className="card-body">
                    <h6 className="text-left pt-2">3 months</h6>
                                    <h6 className="text-left pt-2">Rs. 9000 to Rs. 200000</h6>
                                    <h6 className="text-left pt-2">Upto 1 time Net Monthly Take Home Salary</h6>
                                    <h6 className="text-left pt-2">6 months</h6>
                                    <h6 className="text-left pt-4 mt-1">Permanent</h6>
                                    <h6 className="text-left pt-4">EMI via Salary Deduction</h6>
                                    <h6 className="text-left pt-2">1.75% per month</h6>
                                    <h6 className="text-left pt-2"><button className=" btn btn-sm click text-white " data-toggle="modal" data-target="#exampleModalCenter">Click here </button></h6>
                                    <h6 className="text-left pt-2">Application Form</h6>
                                    <h6 className="text-left pt-2">2% + GST  on Outstanding</h6>
                                    <h6 className="text-left pt-2">Not permitted</h6>
                                    <h6 className="text-left pt-4">0.067% for each day of delay</h6>
                                    <h6 className="text-left pt-4">2-3 Days</h6>
                       </div>
                    </div>
                    </div> */}

                  </div>

                  
                </div>
:null}
  <button style={{background:color1}} className=" btn btn-md sixmonth text-white  mt-1  mx-auto" onClick={()=>{setShow1(true); /* setShow(false) */;setColor1( '#003366')}}>MYFIN - 6 MONTHS</button>
{/* <button className=" btn btn-md sixmonth text-white mt-lg-3 mt-3 bg-dark mx-auto" onClick={()=>{setShow1(true); setShow(false)}}>MYFIN - 6 MONTHS</button><br/> */}
{show1 &&
                <div className="col-sm-12 col-12  pt-4">
                <div className="card border-dark">

                  <table className="table">
                    <tbody >
                      <tr >
                        <td className="font-weight-bold text-left tenor pt-4">Tenor</td>
                        <td className="text-left  pt-4">6 months</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold text-left  pt-2">Loan Amount</td>
                        <td className="text-left  pt-2">Rs. 9000 to Rs. 200000</td>
                      </tr>
                      <tr className="pt-0">
                        <td className="font-weight-bold text-left  pb-2">Maximum Loan Eligibility</td>
                        <td className="text-left  pt-2">Upto 2 times Net Monthly Take Home Salary</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Minimum Employment</td>
                        <td className="text-left  pt-2">12 months</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Status of Employment</td>
                        <td className="text-left  pt-2">Permanent</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Repayment</td>
                        <td className="text-left  pt-2">EMI via Salary Deduction</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Interest Rate</td>
                        <td className="text-left  pt-2">1.75% per month</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Other Charges – PF & AMC</td>
                        <td className="text-left  pt-3"><button className=" btn btn-sm click text-white " data-toggle="modal" data-target="#exampleModalCenter2">Click here</button></td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Application</td>
                        <td className="text-left  pt-2">Application Form</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Foreclosure</td>
                        <td className="text-left  pt-2">2% + GST  on Outstanding</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Partial Prepayment</td>
                        <td className="text-left  pt-2">Not permitted</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Penal Interest for Overdue Payments</td>
                        <td className="text-left  pt-2">0.067% for each day of delay</td>
                      </tr>

                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Turn Around Time</td>
                        <td className="text-left  pt-2">2-3 Days</td>
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2">Documentation required</td>
                        <td className="text-left  pt-2">Aadhaar Card <br/>
                        Current Address proof – Latest Electricity Bill, Telephone Bill, Mobile Bill, Rent Agreement (if different in Aadhaar)<br/>
                        Pan Card <br/>
                        1 month Bank statement (latest) with salary credit reflection <br/>
                        Photo <br/>
                        Latest Salary slip <br/>
                        1 Security Cheque <br/>
                        All to be self-attested by Customer</td>
                        
                      </tr>
                      <tr >
                        <td className="font-weight-bold text-left  pt-2 pb-3">Process</td>
                        <td className="text-left pt-2">Online</td>
                      </tr>
                    </tbody>
                  </table>
                  
                  

                  </div>

                  
                </div>
}
            </div>
            </section>
            <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div className="modal-content">
      {/* <div className="modal-header"> */}
        {/* <h5 className="modal-title  font-weight-bold" id="exampleModalLongTitle">MYFIN 3 - MONTHS LOAN - OTHER CHARGES CHART</h5> */}
        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> */}
      {/* </div> */}
      <div className="container-fluid modal-body ml-3">
      <h4 className="modal-title  font-weight-bold pt-4" id="exampleModalLongTitle ">MYFIN 3 - MONTHS LOAN - OTHER CHARGES CHART</h4>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      {/* <span aria-hidden="true">&times;</span> */}
          <img src="ASSETS/cancel.png" alt=""/>
        </button>
        <div className="row  p-lg-5 productrow  ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3 pt-lg-0">
                <div className="card p-3">
                <h4 className="text-center font-weight-bold pb-2">PROCESSING FEES*</h4>
                {/* <h5 className="text-center font-weight-bold pb-4">LOAN AMOUNT</h5> */}
                
                <table className="p-4">
                <thead>
    <th colspan="2">LOAN AMOUNT (₹)</th>
    <th>TENURE</th>
   
  </thead>
 
                    <thead > 
                        <tr>
                <th >FROM</th>
                <th>TO</th>
                <th>3 - MONTHS</th>
                </tr>
                </thead>
                <br/>
                <tbody className="border-none ">
                <tr>
    <td data-label="From" className="font-weight-bold">0  </td>
    <td data-label="To " className="font-weight-bold">15000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 120 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">15001 </td>
    <td data-label="To" className="font-weight-bold">25000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 190 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">25001 </td>
    <td data-label="To" className="font-weight-bold">35000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 260 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">35001 </td>
    <td data-label="To" className="font-weight-bold">50000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 375 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">50001 </td>
    <td data-label="To" className="font-weight-bold">75000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 560 </td>
  </tr>
  <tr >
    <td data-label="From" className="font-weight-bold">75001 </td>
    <td data-label="To" className="font-weight-bold">100000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 750 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">100000 </td>
    <td data-label="To" className="font-weight-bold">200000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 1,500 </td>
  </tr>
                </tbody>
                </table>
               
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-5 pt-lg-0">
                <div className="card p-3">
                <h4 className="text-center font-weight-bold pb-2">ACCOUNT MAINTENANCE CHARGES*</h4>
               
                <table className="p-4">
                <thead>
    <th colspan="2">LOAN AMOUNT (₹)</th>
    <th>TENURE</th>
   
  </thead>
                    <thead>
                     
                <th>FROM</th>
                <th>TO</th>
                <th>3 - MONTHS</th>
                </thead>
               <br/>
                <tbody>
                <tr >
    <td data-label="From" className="font-weight-bold">0 </td>
    <td data-label="To" className="font-weight-bold">15000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 120 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">15001 </td>
    <td data-label="To" className="font-weight-bold">25000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 190 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">25001 </td>
    <td data-label="To" className="font-weight-bold">35000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 260 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">35001 </td>
    <td data-label="To" className="font-weight-bold">50000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 375 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">50001 </td>
    <td data-label="To" className="font-weight-bold">75000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 560 </td>
  </tr>
  <tr >
    <td data-label="From" className="font-weight-bold">75001 </td>
    <td data-label="To" className="font-weight-bold">100000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 750 </td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">100000 </td>
    <td data-label="To" className="font-weight-bold">200000 </td>
    <td data-label="3 Months" className="font-weight-bold">₹ 1,500 </td>
  </tr>
                </tbody>
                </table>
               
                </div>
            </div>
        </div>
        <h6 className="text-center pt-4 pt-lg-0">*GST will be charged additionally.</h6>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
<div className="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div className="modal-content">
      {/* <div className="modal-header"> */}
        {/* <h5 className="modal-title  font-weight-bold" id="exampleModalLongTitle">MYFIN 3 - MONTHS LOAN - OTHER CHARGES CHART</h5> */}
        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> */}
      {/* </div> */}
      <div className="container-fluid modal-body ml-3">
      <h4 className="modal-title  font-weight-bold pt-4" id="exampleModalLongTitle ">MYFIN 6 - MONTHS LOAN - OTHER CHARGES CHART</h4>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      {/* <span aria-hidden="true">&times;</span> */}
          <img src="ASSETS/cancel.png" alt=""/>
        </button>
        <div className="row  p-lg-5 productrow">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-4 pt-lg-0">
                <div className="card p-3">
                <h4 className="text-center font-weight-bold pb-2">PROCESSING FEES*</h4>
                {/* <h5 className="text-center font-weight-bold pb-4">LOAN AMOUNT</h5> */}
                <table className="p-4">
                <thead>
    <th colspan="2">LOAN AMOUNT (₹)</th>
    <th>TENURE</th>
   
  </thead>
                    <thead > 
                        <tr>
                <th >FROM</th>
                <th>TO</th>
                <th>6 - MONTHS</th>
                </tr>
                </thead>
                <br/>
                <tbody className="border-none">
                <tr>
    <td data-label="From" className="font-weight-bold">0 </td>
    <td data-label="To" className="font-weight-bold">15000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 225</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">15001 </td>
    <td data-label="To" className="font-weight-bold">25000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 375</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">25001 </td>
    <td data-label="To" className="font-weight-bold">35000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 525</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">35001 </td>
    <td data-label="To" className="font-weight-bold">50000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 750</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">50001 </td>
    <td data-label="To" className="font-weight-bold">75000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 1,125</td>
  </tr>
  <tr >
    <td data-label="From" className="font-weight-bold">75001 </td>
    <td data-label="To" className="font-weight-bold">100000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 1,500</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">100000 </td>
    <td data-label="To" className="font-weight-bold">200000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 3,000</td>
  </tr>
                </tbody>
                </table>
               
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 col-sm-12 pt-5 pt-lg-0">
                <div className="card p-3">
                <h4 className="text-center font-weight-bold pb-2">ACCOUNT MAINTENANCE CHARGES*</h4>
                {/* <h5 className="text-center font-weight-bold pb-4">LOAN AMOUNT</h5> */}
                <table className="p-4">

                <thead>
    <th colspan="2">LOAN AMOUNT (₹)</th>
    <th>TENURE</th>
   
  </thead>
                    <thead>

                <th>FROM</th>
                <th>TO</th>
                <th>6 - MONTHS</th>
                </thead>
               <br/>
                <tbody>
                <tr >
    <td data-label="From" className="font-weight-bold">0 </td>
    <td data-label="To" className="font-weight-bold">15000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 225</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">15001 </td>
    <td data-label="To" className="font-weight-bold">25000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 375</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">25001 </td>
    <td data-label="To" className="font-weight-bold">35000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 525</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">35001  </td>
    <td data-label="To" className="font-weight-bold">50000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 750</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">50001 </td>
    <td data-label="To" className="font-weight-bold">75000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 1,125</td>
  </tr>
  <tr >
    <td data-label="From" className="font-weight-bold">75001 </td>
    <td data-label="To" className="font-weight-bold">100000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 1,500</td>
  </tr>
  <tr>
    <td data-label="From" className="font-weight-bold">100000 </td>
    <td data-label="To" className="font-weight-bold">200000 </td>
    <td data-label="6 Months" className="font-weight-bold">₹ 3,000</td>
  </tr>
                </tbody>
                </table>
               
                </div>
            </div>
        </div>
        <h6 className="text-center pt-4 pt-lg-0">*GST will be charged additionally.</h6>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>

{/* <hr className="container-fluid otherloanhr  d-none d-lg-block"></hr> */}
<footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
            {/* <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
                <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
         
       </>
    )
}

export default Product
