import React from 'react'
import './Corporate.css'
import { NavLink,useHistory} from 'react-router-dom'

const Corporate=(props)=> {
  const history=useHistory()
  const enrollNowHandler=()=>{
   history.push("/contactus")
  }
    return (
       <>
       <hr className="container-fluid abouthr d-none d-lg-block"></hr> 
       <div className="corporateimg img-fluid d-lg-block d-md-block d-none">
       <div className="container text-left  corporatehearderset">
       <h2 className="container display-5 font-weight-bold font-weight-bold text-white">CORPORATES</h2>
      </div>
       </div>
       {/* <div className="trainglecorporate d-none d-xl-block d-lg-none"></div> */}
 <div className="corporateimg1 img-fluid d-lg-none d-md-none d-block">
       <div className="container text-left  corporatehearderset">
       <h2 className="container display-5 font-weight-bold font-weight-bold text-white">CORPORATES</h2>
      </div>

       </div>
       <div className="container mt-5 pt-2 importance">
       <h3 className="text-lg-left text-center font-weight-bold ">{/* pl-3 pr-3 pl-lg-0 pr-lg-0 */}<span>MYFIN </span>- IMPORTANCE OF A TIE UP</h3>
       <h5 className="text-lg-left text-center pt-5">As a corporate, you must often be facing requirements of advances by your employees to meet some of their urgent requirements. It is an accepted fact that money required on an urgent basis can be quite stressful specially if there are no avenues to raise this money and can cause loss of productivity to absenteeism by the employees who are facing such a situation. </h5>
       <h5 className="text-lg-left text-center pt-5">Myfin ties up with corporates such as yourselves to offer your employees such advances as and when they may be faced with a tight situation. </h5>
        </div>

       <section className="container-fluid  mt-5 tieup">
      
       <div className="row ">
       <div className="col-lg-4">
       </div>
       <div className="col-lg-8 p-5 col-sm-12 col-12">
       <h4 className="text-lg-left text-center font-weight-bold">WHY SHOULD A CORPORATE TIE-UP WITH MYFIN?</h4>
              <h5 className="text-lg-left text-center pt-3"> Everyone faces a dilemma on whom to approach when faced with an urgent need to raise money considering the social stigma attached to the same and the situation is no different when it comes to any of your employees who are in such a situation:</h5>
       <div className="row mt-5">
       <div className="col-lg-5 col-md-5 col-sm-12 col-12 pt-1 pt-lg-0 ml-md-5 ml-lg-0">

       <div className="card ">
      <div className="card-header text-white font-weight-bold">
 Whom to ask, Where to go?
  </div>
  <div className="card-body">
    <h5 className="card-text text-left">Employer</h5>
    <h5 className="card-text text-left">Family</h5>
    <h5 className="card-text text-left">Friends</h5>
    <h5 className="card-text text-left">Colleagues</h5>
    <h5 className="card-text text-left">Neighbors</h5>
     <h5 className="card-text text-left">Local Money Lender</h5>
   
  </div>
       </div>
       </div>
         <div className="col-lg-4 ml-lg-5 col-md-5 col-sm-12 col-12 pt-4 pt-md-2 pt-lg-0 mx-auto">       
       <div className="card">
       <div className="card-header text-white font-weight-bold">
Consequences
  </div>
  <div className="card-body">
    <h5 className="card-text text-left">Embarrassment</h5>
    <h5 className="card-text text-left">Absenteeism</h5>
    <h5 className="card-text text-left">Obligation</h5>
    <h5 className="card-text text-left">Rejection</h5>
    <h5 className="card-text text-left">Stress</h5>
     <h5 className="card-text text-left">Stigma</h5>
   
  </div>
       </div>
       </div>
       </div>
       </div>
      </div>
      
       </section>

       <section className="container-fluid p-5 benefits mt-3 ">
       <div className="benefitimg img-fluid container-fluid img-responsive">
         <h5 className="text-center font-weight-bold pl-lg-5 pr-lg-5  benefitheading">An easy way out of this situation for a corporate could be to be least bothered about the requirement of their employees but this approach is a strict no-no to any corporate that thinks in terms of the welfare of its employees, the efficiency and productivity levels of the employees and the overall well-being of the employees.</h5>
             
      <div className="row p-lg-5 mb-3 ">
        <div className="col-lg-1 col-md-1 col-sm-12 col-12 pt-5 pt-lg-0 ">
          </div>
          
          <div className="col-lg-5 col-md-5 col-12 col-sm-12 mx-auto mr-md-5 mr-lg-0 ">
            <div className="card border-dark benefitcard1 mt-md-5 mt-lg-0">
                  <div className="card-header  text-white mt-0  font-weight-bold pt-4 benefit-header">{/*mt-4 replace by mt-0 */ }
                Some of the benefits to the employee are listed below:
                </div>
                
                <div className="card-body">
                  <h5 className="text-left font-weight-bold">CONVENIENT -</h5>
                  <h6 className="text-left">Can apply from his desktop or his mobile.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h5 className="text-left font-weight-bold">CONFIDENTIALITY -</h5>
                  <h6 className="text-left">Doesn't need to seek money from multiple sources.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h5 className="text-left font-weight-bold">SPEED -</h5>
                  <h6 className="text-left">Can get his loan in his bank account in a matter of hours.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h5 className="text-left font-weight-bold">HASSLE FREE & TIMELY  -</h5>
                  <h6 className="text-left">Can get his loan as per requirement & when needed.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h5 className="text-left font-weight-bold">CERTAINTY -</h5>
                  <h6 className="text-left">Chances of loan rejections are low.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h5 className="text-left font-weight-bold">SIMPLE -</h5>
                  <h6 className="text-left ">Paperless process easy to follow.</h6>
                  </div>
              </div>
          </div>
          <div className="col-lg-5 col-md-5 col-12 col-sm-12 pt-5 pt-lg-0">
            <div className="card border-dark benefitcard2">
              <div className="card-header text-white mt-0  font-weight-bold pt-4">{/*mt-2 replace mt-0 */}
              There are benefits that accrue to you as a corporate too.<br className="d-none d-lg-block d-md-block"/> Some of them are listed below:<br/>
                </div>
                <div className="card-body">
                  <h6 className="text-left">Can be positioned as a HR benefit/ facility for employees.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">Help prevent loss of office productivity due to easy availability of funds when needed.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">Maintain employee morale especially during financial emergencies.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">An alternative option for employer to provide advance against salary.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">Reduce administrative hassle of maintaining records for advance given.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">Don't have to refuse the employee in case there is no advance policy.</h6>
                  <hr className="benefitcardhr"></hr>
                  <h6 className="text-left">Can prevent blocking of working capital and interest costs in case there is a salary advance policy within the organisation</h6>
                  </div>
              </div>
          </div>
          <div className="col-lg-1">
          </div>
      </div>
<div className="row">
  <div className="col-12">
      <div className="container form pt-4 mt-md-4 mt-lg-0">
<h5 className="text-center text-white font-weight-bold ">Kindly fill the form below and we will be happy to get in touch with you at the earliest:</h5>
<button className="btn btn-sm enrollbutton mt-lg-4 mt-2 d-sm-block mx-auto mb-3 mb-lg-2" onClick={enrollNowHandler}>ENROLL NOW</button><br/>
      </div>
      </div>
      </div>
      </div>
       </section>

       <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
       {/* <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"><button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
                <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
       </>
    )
}

export default Corporate
