import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home'
import About from './Components/About';
import ScrollTop from './Components/ScrollTop';
import Faq from './Components/Faq';
import Product from './Components/Product';
import Corporate from './Components/Corporate';
import Otherloan from './Components/Otherloan';
import ContactUs from './Components/ContactUs';
import Associate from './Components/Associate';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

function App() {
  return (
    <div className="App">
      {/* <Counter target={150} title='Customer' duration={1} /> */}
      <ScrollTop/>
     <Navbar/>
     <Switch>
<Route exact path="/home" component={Home}  />
 <Route path="/About" component={About}/>
 <Route path="/faq" component={Faq}/>
 <Route path="/product" component={Product}/>
  <Route path="/corporate" component={Corporate}/>
  <Route path="/otherloan" component={Otherloan}/>
  <Route path="/contactus" component={ContactUs}/>
  <Route path="/associate" component={Associate}/>
  <Route exact path="/">
    <Redirect to="/home" />
</Route>
 {/* <Route path="/about/#concept" component={About}/> */}
{/* <Route path="/corporate" component={Corporate}/> */}
{/* <Route path="/product" component={Product}/> */} 


     </Switch>
    </div>
  );
}

export default App;
