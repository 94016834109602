import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Associate.css";
import axios from "axios";
import moment from "moment";
import urlData from "../UrlData"
const Associate = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [location, setLocation] = useState("");

  const [emailErrorCode, setemailErrorCode] = useState("");
  const [mobileErrorCode, setmobileErrorCode] = useState("");
  const [dobErrorCode, setdobErrorCode] = useState("");

  const numberDetailsArray = [
    {
      image: "ASSETS/Group 301.png",
      text: "Introduce / Refer us to your existing network / contacts / relationships who are working in corporates.",
    },
    {
      image: "ASSETS/Group 302.png",
      text: "Support, through your network, in empaneling such corporates for our salary advance product.",
    },
    {
      image: "ASSETS/Group 303.png",
      text: " Earn income for ever till such time as the corporate remains empaneled with us their employees are availing of salary advance loans through us.",
    },
  ];
  
  const AssociateForm = (e) => {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var mnumber = /^[0-9\b]+$/;

    const getAge = (dob) =>
      Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e10);

    if (
      name === "" ||
      email === "" ||
      dob === "" ||
      mobileno === "" ||
      location === ""
    ) {
      alert("Please fill up all details");
    } else if (reg.test(email) == false) {
      setemailErrorCode("Invalid Email Address");
      return false;
    } else if (mnumber.test(mobileno) == false) {
      setmobileErrorCode("Please enter only numbers");
    } else if (mobileno.length !== 10) {
      setmobileErrorCode("Please enter valid phone number");
    } else if (18 > getAge(dob)) {
      alert("Age should be minimum 18 years old");
    } else {
      var data = {};
      data.name = name;
      data.dob = dob;
      data.email = email;
      data.contactNo = mobileno;
      data.location = location;

      axios({
        method: "post",
        url: new URL(urlData + "applicants/associateQuery"),
        data: data,
      })
        .then((response) => {
          console.log("create", response);
          setShow(true);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const emailhandleChange = (e) => {
    setEmail(e.target.value);
    var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(e.target.value) == false) {
      // alert('Invalid Email Address');
      setemailErrorCode("Invalid Email Address");
      return false;
    } else {
      setemailErrorCode("Valid Email Address");
    }
  };
  const mobilehandleChange = (e) => {
    var mnumber = /^[0-9\b]+$/;
    setMobileNo(e.target.value);
    if (mnumber.test(e.target.value) == false) {
      setmobileErrorCode("Please enter only numbers");
    } else if (e.target.value.length != 10) {
      setmobileErrorCode("Please enter valid mobile number");
    } else {
      setmobileErrorCode("Mobile No. is Valid");
    }
  };
  const dobhandleChange = (e) => {
    setDOB(e.target.value);
    const getAge = (dob) =>
      Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e10);
    if (18 > getAge(e.target.value)) {
      setdobErrorCode("Age should be minimum 18 years old");
    } else {
      setdobErrorCode("Date of Birth is Valid");
    }
  };
  return (
    <>
      <hr className="container-fluid abouthr d-none d-lg-block"></hr>

      <div className="associateimg img-fluid d-none d-lg-block d-md-block">
        <div className="container text-left  associatehearderset">
          <h2 className="container display-5 font-weight-bold font-weight-bold ">
            ASSOCIATES
          </h2>
        </div>
      </div>
      <div className="associateimg img-fluid d-block d-lg-none d-md-none">
        <div className="container text-left  associatehearderset">
          <h2 className="container display-5 font-weight-bold font-weight-bold ">
            ASSOCIATES
          </h2>
        </div>
      </div>
      {/* <div className="traingleassociate d-none d-xl-block d-lg-none"></div> */}

      <section className="container-fluid associates_steps mt-5 pt-3 mb-5">
        <div className="row mb-4 steps">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  p-4 ml-lg-4 ml-md-4 mx-auto">
            <h4 className="text-white text-lg-left  text-md-left font-weight-bold">
              Have you ever heard of continuous earning throughout your life?
            </h4>
            <h4 className="text-white text-lg-left text-md-left font-weight-bold">
              Presenting a never before oppertunity to generate income for ever
              in 3 easy steps.
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            {numberDetailsArray.map((t, i) => (
              <div className="row mt-5 pt-2 p-4 ml-lg-4 ml-md-4 mx-auto" key={t.text}>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4">
                  <img src={t.image} alt="" className="ml-lg-4" />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                  <h5 className="font-weight-bold mt-4">
                    {t.text}
                  </h5>
                </div>
              </div>
            ))}
            {/*<div className=" row mt-5 pt-2 circlediv datatext">
              <div className="col-lg-1 col-md-1 col-sm-1 col-1 ml-lg-5 pl-lg-5 mt-5">
                <img src="ASSETS/Group 301.png" alt="" className="ml-lg-4 " />
              </div>

              <div className=" col-lg-10 col-md-10 col-sm-11 col-10 ">
                <h5 className="font-weight-bold  pl-lg-5  ml-5 mt-5 text-left ">
                  Introduce / Refer us to your existing network / contacts /
                  relationships who are working in corporates.
                </h5>
              </div>
            </div>
            <div className="row circlediv">
              <div className="col-lg-1 col-md-1 col-sm-1 col-1 ml-lg-5 pl-lg-5 pt-1 mt-lg-5 pt-lg-2 mt-5">
               
                <img src="ASSETS/Group 302.png" alt="" className="ml-lg-4 " />
              </div>
              <div className=" col-lg-10 col-md-10 col-sm-11 col-10 mt-lg-5 mt-4">
                <h5 className="font-weight-bold pl-lg-5 ml-5 mt-4 text-left ">
                  Support, through your network, in empaneling such corporates
                  for our salary advance product.
                </h5>
              </div>
             
            </div>
            <div className="row circlediv ">
              <div className="col-lg-1 col-md-1 col-sm-1 col-1 ml-lg-5 pl-lg-5 pt-1 mt-lg-5 pt-lg-2 mt-5">
                <img src="ASSETS/Group 303.png" alt="" className="ml-lg-4 " />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-11 col-10 mt-lg-5 mt-4">
                <h5 className="font-weight-bold  pl-lg-5 ml-5 mt-5 text-left ">
                  Earn income for ever till such time as the corporate remains
                  empaneled with us their{" "}
                  
                  employees are availing of salary advance loans through us.
                </h5>
              </div> 
            </div>*/}
            <section className="container-fluid  associate_interesting pl-lg-5 pl-md-5 ml-lg-5 ml-md-5 mt-5 pt-2 d-none d-lg-block d-md-block">
              <h3 className="text-lg-left text-md-left pl-2 font-weight-bold mt-4 mt-lg-0 mt-md-0">
                EASY AND INTERESTING, ISN'T IT?
              </h3>
              <h5 className="text-lg-left text-md-left mt-4 ml-lg-2 font-weight-bold mb-lg-3 ">
                If you aren't an associate with us already,&nbsp;&nbsp;
                <button
                  className="btn btn-md click text-white mt-2 mt-lg-0 mt-md-0"
                  data-toggle="modal"
                  data-target="#exampleModalCenter2"
                >
                  CLICK HERE
                </button>
                &nbsp;&nbsp; Now!!!
              </h5>
            </section>
            <section className="container-fluid bg-light associate_interesting pl-lg-5 pl-md-5 ml-lg-5 ml-md-5 mt-5 pt-2 d-lg-none d-md-none">
              <h3 className="text-lg-left text-md-center pl-2 font-weight-bold mt-4 mt-lg-0 mt-md-0">
                EASY AND INTERESTING, ISN'T IT?
              </h3>
              <h5 className="text-lg-left text-md-center mt-4 ml-2 font-weight-bold mb-lg-3 ">
                If you aren't an associate with us already,
              </h5>
              <h6>
                <button
                  className="btn btn-md click text-white mt-2 mt-lg-0 mt-md-0"
                  data-toggle="modal"
                  data-target="#exampleModalCenter2"
                >
                  CLICK HERE
                </button>
                &nbsp;&nbsp; Now!!!
              </h6>
              <br className="d-lg-none d-md-none" />
              <br className="d-lg-none d-md-none" />
            </section>
          </div>

          <div className="col-lg-4 d-none d-lg-block d-md-none stepshand">
            <img src="ASSETS/Three-Steps-hand.png" alt="" />
          </div>
        </div>
      </section>
      {/* <section className="container associate_interesting pl-5 ml-5">
                 <h3 className="text-left pl-2 font-weight-bold">EASY AND INTERESTING, ISN'T IT?</h3>
                 <h5 className="text-left mt-4 ml-2 font-weight-bold mb-3">If you aren't an associate with us already, <button className=" btn btn-md click text-white" data-toggle="modal" data-target="#exampleModalCenter2">CLICK HERE</button>  Now!!!</h5>
            </section> */}

      <div
        className="modal fade"
        id="exampleModalCenter2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="container-fluid modal-body ml-3">
              {/* <h5 className="modal-title  font-weight-bold pt-4" id="exampleModalLongTitle ">MYFIN 6 -OTHER CHARGES CHART</h5> */}
              <button
                type="button"
                className="close formclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
                <img src="ASSETS/cancel.png" alt="" />
              </button>
              {show ? (
                <div className="container  mr-3 thankyou pb-5 mb-3">
                  <img src="ASSETS/thank-you.png" alt="" />
                  <h5 className="text-center ml-3 mr-5 mt-3">
                    for expressing your interest in joining our ever-growing
                    family. We will connect with you shortly to take this
                    forward.
                  </h5>
                </div>
              ) : (
                <div className="container-fluid text-align-center ml-lg-3 associte_model_form">
                  <form action="" method="post" encType="multipart/form-data ">
                    <label className="text-left float-left mt-5 pt-2">
                      Name
                    </label>
                    <input
                      type="text"
                      /* placeholder="Name" */ className="w-lg-50 container border border-dark  associate_name mt-5 p-2  "
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <label className="text-left float-left mt-lg-4 mt-3 pt-lg-1 associate_birth ">
                      Date of <br className="d-lg-none d-md-none" />
                      Birth
                    </label>
                    <input
                      type="date"
                      /* placeholder="Date of Birth" */ className="w-lg-50 container border border-dark   mt-3 p-2 mr-lg-5 ml-4 mr-3"
                      value={dob}
                      onChange={dobhandleChange}
                    />
                    <p
                      className="mt-2 text-center ml-5 pl-4"
                      style={
                        dobErrorCode != "Date of Birth is Valid"
                          ? { color: "red" }
                          : { color: "green" }
                      }
                    >
                      <strong>{dobErrorCode}</strong>
                    </p>
                    {/* <br/> */}
                    {/* <p className="mt-2 text-center" style={emailErrorCode!='Valid Email Address'?{color: "red"}: {color: "green"}}><strong>{dobErrorCode}</strong></p>  */}
                    {/* <label className="text-left float-left mt-4 pt-2">Mobile <br className="d-lg-none d-md-none"/> Number</label> */}
                    <label className="text-left float-left mt-lg-1 pt-lg-1 associate_mobile">
                      Mobile <br className="d-lg-none d-md-none" /> Number
                    </label>
                    <input
                      type="text"
                      /* placeholder="Mobile Number" */ className="w-lg-50 container border border-dark  mt-lg-0 p-2 mr-lg-5 mr-2 ml-3 ml-lg-1"
                      value={mobileno}
                      onChange={mobilehandleChange}
                    />
                    <p
                      className="mt-2 text-center ml-5 pl-4"
                      style={
                        mobileErrorCode !== "Mobile No. is Valid"
                          ? { color: "red" }
                          : { color: "green" }
                      }
                    >
                      <strong>{mobileErrorCode}</strong>
                    </p>
                    {/* <br className="d-lg-none d-md-none"/> */}

                    {/* <label className="text-left float-left mt-lg-1 pt-lg-1 associate_mobile">Mobile  <br className="d-lg-none d-md-none"/> Number</label>
                 <input type="text"  className="w-lg-50 container border border-dark  mt-lg-0 p-2 mr-lg-5 mr-2 ml-3 ml-lg-1" value={mobileno} onChange={mobilehandleChange}/>
                 <p className="mt-2 text-center ml-5 pl-4" style={mobileErrorCode!=="Mobile No. is Valid"?{color: "red"}: {color: "green"}}><strong>{mobileErrorCode}</strong></p> */}

                    <label className="text-left float-left mt-2 pt-lg-1 associate_mail">
                      Mail Id
                    </label>
                    <input
                      type="text"
                      className="w-lg-50 container border border-dark p-2 ml-2 ml-lg-4 mr-lg-1 "
                      value={email}
                      onChange={emailhandleChange}
                    />
                    <p
                      className="mt-2 text-center ml-5 pl-4"
                      style={
                        emailErrorCode != "Valid Email Address"
                          ? { color: "red" }
                          : { color: "green" }
                      }
                    >
                      <strong>{emailErrorCode}</strong>
                    </p>

                    <label className="text-left float-left mt-2 pt-lg-1">
                      Location
                    </label>
                    <input
                      type="text"
                      /* placeholder="Location" */ className="w-lg-50 container border border-dark p-2 ml-2"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <br />
                    {/* <button className="w-50 ml-0 mr-4 mb-4 apply3 text-white border border-dark  mt-3 p-3" data-toggle="modal" data-target="#exampleModalCenter3" onClick={AssociateForm}>
                         SUBMIT</button> */}
                    <button
                      className="btn btn-md apply3 text-white ml-0 mr-4 mt-4 pl-4 pr-4 mb-2"
                      onClick={AssociateForm}
                    >
                      SUBMIT
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <footer className="site-footer ml-lg-5">
        <div className="container ">
          <div className="row ml-lg-2">
            <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
              <div className="imgdiv mb-4">
                <img
                  src="ASSETS/Group 234.png"
                  className="order-2 order-lg-1 mr-lg-0 "
                  alt=""
                />
              </div>

              <p className="text-lg-left text-center text-md-left">
                Myfin - A simple 3 step online process to raise <br /> immediate
                money between 2 salary cycles. <br /> Everyone, very often,
                faces with a sudden <br /> requirement of funds.
              </p>
              <h5 className="text-dark text-lg-left text-md-left  mt-4">
                These could be for any of the following:
              </h5>
              <p className="text-dark text-lg-left text-md-left pb-2 pay">
                <span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br />
                <span>◉</span>&nbsp;&nbsp;Make Credit card payments
              </p>

              <a href="#concepts">
                {" "}
                <button className="btn btn-md apply3 text-white">
                  KNOW MORE
                </button>
              </a>
            </div>
            <div className="vertical-line d-none d-sm-block"></div>
            <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
              <h6 className="font-weight-bold d-none d-sm-block">
                QUICK LINKS
              </h6>
              <ul className="footer-links d-none d-sm-block mt-lg-4">
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <a href="#team">Team</a>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">How we work</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Products</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Contact Us</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Privacy Policy</NavLink>
                </li>
                <li className="text-justify ml-lg-4 mt-1">
                  <NavLink to="/">Terms & Conditions</NavLink>
                </li>
              </ul>
            </div>
            <div className="vertical-line d-none d-sm-block"></div>
            <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
              <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
              <ul className="footer-links d-none d-sm-block mt-lg-4">
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Salaried</NavLink>
                </li>
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Business</NavLink>
                </li>
                <li className="text-justify ml-lg-2 mt-1">
                  <NavLink to="/">Professional</NavLink>
                </li>
              </ul>

              <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">
                CONNECT WITH US
              </h6>
              <div className="row mt-3">
                <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg"></div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                  <img src="ASSETS/Group 79.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                  <img src="ASSETS/Group 80.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                  <img src="ASSETS/Group 61.png" alt="" />
                </div>
                <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                  <img src="ASSETS/Group 63.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <footer className="site-footer associatefooter">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
      <div className="container-fluid lastfooter">
        <div className="row ">
          <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
            <p className="copyright-text text-white pt-2">
              All rights reserved. | Privacy Policy. | Copyright with Fiscus
              Services Private Limited.
            </p>
          </div>

          <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
            <p className="copyright-text text-white pt-2">
              Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br className="d-lg-none d-md-none d-block" />
              Designed by : Beak Media
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Associate;
