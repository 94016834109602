import React from 'react'
import { NavLink } from 'react-router-dom'

import './About.css'

const About=() =>{
    const navigateToSignUp = () => {
        window.open('https://user.myfin.in/#/register', '_blank', 'noopener,noreferrer');
      };
    return (
        <>
         <hr className="container-fluid abouthr d-none d-lg-block"></hr>
         {/*<div className="traingle d-lg-none d-xl-block"></div>  */}
        <div className="container about ">
            
        <h4 className="container display-5 text-left pt-4 " ><strong>ABOUT <span>US</span></strong></h4>
        </div>
         <section className="container-fluid companymission ">
        <div className="row ml-lg-3 ml-xl-5 companymissionrow1">
    
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 companyrow col-md-pull-6 order-10 order-lg-0 ">
            <h4 className="container display-5 font-weight-bold text-left pt-3" >Company Mission</h4> 
           <div className="row pt-3 ">
               <div className="col-lg-1 col-md-1 col-sm-1 col-1">
               <img src="ASSETS/icon.png" alt="" className="ml-3 ml-md-4"/>
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10 ">
               <h5 className="text-left ml-3 ml-lg-4 ml-md-0 ml-xl-2">To  Offer all kinds of innovative and "need of the hour" customized retail lending products</h5><br/> 
               </div>
               </div>
               <div className="row ">
               <div className="col-lg-1 col-md-1 col-sm-1 col-1">
               <img src="ASSETS/icon.png" alt="" className="ml-3 ml-md-4"/>
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10 ">
               <h5 className="text-left ml-3 ml-lg-4  ml-md-0 ml-xl-2">Aimed at addressing the financial needs of the unserved and the underserved segment thereby encouraging financial inclusion without compromising the quality</h5><br/> 
               </div>
               </div>
               <div className="row  ">
                   
               <div className="col-lg-1 col-md-1 col-sm-1 col-1">
               <img src="ASSETS/icon.png" alt="" className="ml-3 ml-md-4"/>
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10 ">
               <h5 className="text-left ml-3 ml-lg-4  ml-md-0 ml-xl-2">With effective use of the technology and automation of standard methods and processes</h5><br/> 
               </div>
               </div>
               <div className="row  ">
               <div className="col-lg-1 col-md-1 col-sm-1 col-1">
               <img src="ASSETS/icon.png" alt="" className="ml-3 ml-md-4"/>
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10 ">
               <h5 className="text-left ml-3 ml-lg-4  ml-md-0 ml-xl-2">Resulting in increased efficiency, productivity and high service standards to the end customer</h5><br/> 
               </div>
               </div>
           
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ml-auto companymission_row2 pt-lg-5">
                <img src="ASSETS/about banner.jpg" alt="" className="img-fluid mt-4"/>
            </div>
        </div>
        </section> 
         <section className="container p-3 team">
            <div className="row mb-5">
                <div className="col-sm-12">
                    <h3 className="text-center font-weight-bold pt-5" id="team">THE <span>TEAM</span></h3>
                </div>
               
            </div>
            <div className="row ml-lg-4 teamrow">
            
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4 mt-lg-0">
                    <div className="team-card">
                        <img src="ASSETS/Group 125.png"  alt=""/>
       
                        <div className="overlay">
                            <h5 className="text-center text-white  p-5 team_bg mt-5">A veteran in the Retail Loans industry having spent over 25 years in this space.</h5>
                            </div>
                    </div>
                    
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4 mt-lg-0">
                    <div className="team-card">
                        <img src="ASSETS/Group 126.png"  alt=""/>
               
                        <div className="overlay ">
                            <h5 className="text-center text-white mt-5 p-5">I am passionate about business, startups, mobile apps and AI.</h5>
                            </div>
                    </div>
                </div>
          
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4 mt-lg-0">
                    <div className="team-card">
                    <img src="ASSETS/Group 127.png"  alt=""/>

                        <div className="overlay">
                            <h5 className="text-center text-white mt-3 p-5">I am ambitious and driven. I thrive on challenge and constantly set goals for myself, so I have something to strive towards.</h5>
                            </div>
                    </div>
                </div>
                <div className="col-lg-1"></div>
            </div>

        </section> 
     
        <div className="container concept">
        <h3 className="container display-5 font-weight-bold text-lg-left Cairo pt-5 "  id={"concepts"}>  MYFIN-<span>THE CONCEPT</span></h3>
        <h4 className="container display-5 font-weight-bold text-lg-left text-md-left  p-3">Myfin - a simple 3 step online process to raise immediate money between 2 salary cycles</h4>  
        <h5 className="container display-5 text-lg-left text-md-left"><b>Everyone, very often, faces with a sudden requirement of funds. These could be for any of the following:</b></h5>
        </div>

        <section className="container-fluid companymission pt-3">
        <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 companyrow p-2 ">
            <div className="row ml-lg-4 ml-xl-5">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">A medical emergency at home </h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">An increase in children's school fees</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Go out with family on a holiday or to attend a family function / occasion </h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Pay security deposit or monthly rent for your house</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Unexpected vehicle payments or vehicle insurance </h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">EMI burden in a month</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Celebrate festivals with family</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Purchase household appliances</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4">Gift someone on their wedding / birthday</h5>
                </div>
                </div>
                <div className="row ml-lg-4 ml-xl-5 ">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                <img src="ASSETS/icon.png" alt="" className="ml-lg-3 ml-2 ml-md-5"/>
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-10">
                <h5 className="text-left ml-2 ml-lg-0 ml-md-4" >Any other such instance which suddenly requires you to raise money... </h5>
                </div>
                </div>
           
       
  
            </div>
            <div className="col-lg-5 col-md-12 col-12 col-sm-12 ml-auto d-none d-md-block concept1 pt-5">
                <img src="ASSETS/Rectangle 31.jpg" alt="" className="img-fluid"/>
            </div>
        </div>
   
        </section> 

        <div className="container">
        <h5 className="container display-5 font-weight-bold text-left Cairo pt-4" >Have you been faced with such a situation before?</h5>
        <h5 className="container display-5 font-weight-bold text-left ">Have you had trouble raising money immediately when you had the need?</h5>  
        <h5 className="container display-5  text-left pt-4">Myfin aims at addressing such situations and helps you get immediate credit in your account and tide over such instances. Stop asking money from friends and family and avoid embarrassing situations. Myfin has tied up with organizations and offer immediate loans to the employees of such corporates to help them meet their financial emergencies.</h5>
        <button className="btn btn-md apply2 text-white mt-lg-4 d-sm-block mt-3 mt-lg-0" onClick={navigateToSignUp}>APPLY NOW</button><br/><br/>
        </div>
        <div className="leadimg img-fluid d-none d-md-block d-lg-block">
            
                <div className="container text-center text-dark my-auto leadheader">
                  
                        <h5 className="container-fluid display-5  text-center">If your company has not yet tied up with us, simply fill out the form below and we will get in touch with your organization and initiate the tie up.</h5>
                    <button className=" btn btn-sm apply2 text-white mt-lg-3 mt-md-4 mx-auto">CORPORATE LEAD FORM</button>
                     
                   
          </div>
          </div>
          
          <div className="leadimg_mobile img-fluid d-lg-none d-md-none d-block">
          <div className="container text-center text-dark my-auto leadheader1">
                    <p className="container-fluid display-5 font-weight-bold text-center ">If your company has not yet tied up with us, simply fill out the form below and we will get in touch with your organization and initiate the tie up.</p>
                    <button className=" btn btn-sm apply2 text-white mt-lg-3  mx-auto">CORPORATE LEAD FORM</button>
              
          </div>
          </div>
          <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
                <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
        </>
    )
}

export default About
