import React from 'react'
import { NavLink} from 'react-router-dom'
import './Faq.css'

const Faq=() =>{
    // const [collapse, setcollapse] = useState(false)
    return (
        <>
         <hr className="container-fluid abouthr d-none d-lg-block"></hr>
         
         <div className="faqimg img-fluid"></div>
         {/* <div className="trainglefaq d-lg-none d-xl-block"></div> */}
        <section className="container Faq">
        <h2 className="text-left font-weight-bold ml-2 mt-5">FAQS</h2>
        <h3 className="text-left font-weight-bold ml-2 mt-4 ">Myfin Loans</h3>
        </section>
        <section className="container">
        <div className="row">
        <div className="col-lg-12">
        <div className="accordion mt-5" id="accordionExample">

        <div className="FaqAccordion">
            <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 iconimg ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is Myfin and how does it work?</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className=" text-left clearfix ml-5 para">
        Myfin Loans are short term Loans ranging between Rs. 2,500 to Rs. 2 lakh for a minimum of 5 days and maximum of 180 days with minimal documentation. It is an Instant Online Loan for Salaried employees to meet sudden financial needs / requirements between 2 Salary dates.
Myfin Loan is a Fintech product being promoted by Fiscus Services Pvt Ltd – an integrated fintech digital lending  platform that offers end to end service on a host of lending products through Online/ Offline channels to all Customer segments through an Associate NBFC. 

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">What are the reasons for taking a Myfin loan?{/* {<img src="ASSETS/PLUS.png"  />} */}{/* <i className="fal fa-minus-circle"></i>  */}</button>
        
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwo" className="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className=" text-left clearfix ml-5 para">
       <p> A Myfin loan is a common solution for immediate, short-term financial needs often caused by unexpected changes in income and/or expenses. Below are a few reasons why people might need a Myfin loan:
        </p>
        <ul>
        <li>Unexpected emergency expenses like medical bills</li>
        <li>Help paying for everyday expenses like rent, food, and utility bills in between pay checks</li>
        <li>Breakdown of one’s vehicle</li>
        <li>Buying a new mobile phone</li>
        <li>Gifting someone on their birthday or wedding</li>
       <li> Payment of school fees, etc</li>
        <li>Payment of house rent / security deposit</li>
        </ul>



            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">What loan products does Myfin offer?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThree" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        Apart from short term loans, Fiscus Services Pvt Ltd (FSPL) is a one stop shop for all types of loans. Myfin (FSPL) offers Home Loans, Personal Loans and Loan against property, etc through its association with many Banks / Financial Institutions for these loan products. 



            </div>
            </div>
        </div>
        {/* <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">What are unsecured loans provided by Myfin? </button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        Unsecured loans are provided by financers, lenders, investors, banks or its agents registered with Myfin at www.Myfin.in for individuals to meet their requirements. No moveable/immoveable assets are required to be placed as collateral for availing unsecured loans.
            </div>
            </div>
        </div> */}
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed"  data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseOne">How are Myfin Loans different from Personal Loans?</button>
        </h2>
        </div>
        </div>
        </div>

        <div id="collapseFive" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        

           <p>A Personal Loan (PL) is for a minimum tenure of 1 year to a maximum of 5 years. Myfin Loans, on the other hand, bridge the gap between 2 salary dates for a minimum period of 5 days and a maximum of 180 days.</p>
               <ul>
            <li>In a PL, the minimum loan amount starts at 50,000 whereas Myfin Loans range between Rs. 2500 to Rs. 2 lakhs.</li>
            <li>Banks have a lock-in period of 6 months to prevent borrowers from prepaying the loan. Prepayment post the lock-in period also attracts penalties. There is no such lock-in or prepayment penalty on Myfin Loans.</li>
            <li>A PL takes anywhere between 4 to 7 days of processing before the customer receives the credit in their account. Myfin Loans are disbursed within a few hours on the same day.</li>
            <li>Credit checks applied by a bank for such loans are quite tight whereas it is much easier to get a Myfin Loan.</li>
            <li>In a PL, one pays interest for the committed tenure of the loan, however, in the case of Myfin Loans, one pays interest only for the number of days that the loan amount is used.</li>
             </ul>
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseOne">Can one apply for a Myfin Loan at a place in their vicinity?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseSix" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        The Myfin Loan application process is entirely online for the customer’s convenience. There’s no need for the customer to leave home to apply at a location in their area. Customer can visit the Myfin website through a laptop/desktop or mobile phone to apply. Decisions are instant, and the loan amount is credited into the customer’s salary account directly.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseOne">Does Myfin accept loan applications 24 * 7?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>

        <div id="collapseSeven" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        Yes. Myfin is an online lender, so one can apply for a loan through the website 24 * 7. If the loan is approved before 12 p.m., money could be deposited in the customer’s account in as little as one business day. If the loan is approved after 12 p.m., the amount will be deposited in the customer’s account by the next business day. One can apply for loans on weekends or holidays as well; in which case, the money will be deposited in the customer’s account by the next business day.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseOne">Why choose Myfin for an <br className="d-lg-none d-md-none d-block"/> instant loan?{/* <img src="ASSETS/PLUS.png"/> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseEight" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
       <p> Myfin offers a short-term personal loan that is a better alternative to a traditional payday loan. Below are some benefits of loans from Myfin.</p>
       <ul>
<li>The customer doesn’t need to worry about hidden fees or debt traps. Myfin products are created to fulfil a requirement and not take advantage of the customer. </li>
<li>The customer does not need to go to a local payday loan store. As an online lender, Myfin allows one to secure a loan from the comfort of their home.</li>
<li>Myfin has an in-house customer service team available to answer questions through emails and phone calls</li>
<li>Myfin is committed to creating long-term relationships with its customers. If one has ongoing financial needs, Myfin is available to help.</li>
<li>Myfin offers loans to people with a low/no credit score</li>
 <li>The customer has an opportunity to rebuild their credit score</li>
 </ul>

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr"></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseOne">Is Myfin a bank or a NBFC?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseNine" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="-body text-left clearfix ml-5 para">
        Myfin (FSPL) is not a bank or a NBFC; it is an integrated fintech platform

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        </div>
        </div>
        </div>
        </section>

        <section className="container Faq">
        <h3 className="text-left font-weight-bold ml-2 mt-4">Loan Criteria and Eligibility</h3>
        </section>
        <section className="container">
        <div className="row faqrow">
        <div className="col-lg-12">
        <div className="accordion mt-5" id="accordionExampleTwo">
        <div className="FaqAccordion">
        <div className="row">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">Who is eligible to apply for <br className="d-lg-none d-md-none d-none d-sm-block"/> Myfin Loans?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        All the employees of the empanelled corporates are eligible to apply for the loan. The employee should be permanent / on rolls of the company.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseOne">What if a customer needs a <br className="d-lg-none d-md-none d-none d-sm-block"/> loan but their company is not empanelled <br className="d-lg-none d-none d-md-block"/>with Myfin?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseEleven" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        Only employees of affiliated companies can enjoy the benefits of Myfin Loans. In case a company is not empanelled, drop an email at customer.supprot@Myfin.in with the company details. Myfin will get in touch with the company to initiate a tie-up with the company for offering Myfin loans to the employees of the company


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTweleve" aria-expanded="true" aria-controls="collapseOne">What is the base minimum salary an employee needs to have to avail <br className="d-lg-none d-md-block d-none"/>  this facility?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTweleve" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix para ml-5">
       
An employee with a net monthly take home of minimum Rs.9000 can avail this facility.



            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseOne">What is the minimum and maximum loan one can secure?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        A customer can secure a minimum loan amount of Rs. 2,500 and maximum of Rs. 2,00,000.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="true" aria-controls="collapseOne">How is the eligibility of the loan calculated?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseFourteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        30% of the net take home salary is the base for the loan eligibility. The eligibility can be increased to up to 2 times of the net take home salary depending upon the tenure of the loan. Myfin uses an internal algorithm which creates the loan eligibility taking into consideration the documents and personal details provided.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="true" aria-controls="collapseOne">Does the customer need to provide any security/guarantee?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseFifteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        No, Myfin trusts its customers. The customer should have completed 6 months of work with the organisation and needs to be a permanent employee of the company. However, a security cheque is required for long tenure loans, which is returned on completion of the repayment of the loan.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="true" aria-controls="collapseOne">What if the customer is taking  <br className="d-lg-none d-md-none d-none d-sm-block"/> a loan for the first time and has no / low credit score? {/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseSixteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        Myfin has provisions for first timers too. At Myfin, an internal algorithm creates a score at the backend and allows the customer to secure a loan, while simultaneously building a credit score.
Myfin provides loans to people with credit score as low as 300 and even to customers who have never availed of a loan at all thereby helping in financial inclusion.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="true" aria-controls="collapseOne">What information does one need to provide in order to be considered for <br className="d-lg-none d-md-block d-none"/>  a Myfin loan <br className="d-lg-block d-xl-none d-md-none d-none"/> approval?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseSeventeen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        <p>To be considered for loan approval, a customer needs to provide the following:</p>
        <ul>
       <li> Personal details like name, address,  pan  no, Adhaar  no, DOB to name a few</li>
       <li> Employment details</li>
       <li> Salary account details</li>
        </ul>


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="true" aria-controls="collapseOne">In how much time can one secure / get approval for the loan?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseEighteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        Loan applications submitted before 12:00 PM are processed the same business day. Loan applications submitted post 12:00 PM are processed the next business day. Loan applications submitted over weekends or during holidays are processed on the next business day.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="true" aria-controls="collapseOne">Is the loan amount directly credited into the customer’s account?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseNineteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">    
          The loan amount is directly credited only to the customer’s salary account.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwenty" aria-expanded="true" aria-controls="collapseOne"> Can a customer take a loan while availing a separate loan from some <br className="d-lg-none d-md-block d-none"/> other place?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwenty" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        Yes, a customer with a pre-existing loan can avail a Myfin loan depending on the verification and credit checks done by the Myfin team.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentyone" aria-expanded="true" aria-controls="collapseOne">Can a customer apply for a Myfin Loan in case they have defaulted in the past?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentyone" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        Yes, a customer can still apply for a Myfin Loan. The eligibility for securing a loan depends upon the documents submitted and the credit score that is created by Myfin’s in-house algorithm. 

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentytwo" aria-expanded="true" aria-controls="collapseOne">What are the possible reasons for a loan application to be rejected?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentytwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        <p>Some reasons due to which the loan can get rejected:-</p>
        <ul>
        <li>False information provided </li>
        <li>Incomplete / incorrect documentation</li>
        <li>Credit score is really low</li>
        <li>Customer is a chronic defaulter</li>
        <li>Not a permanent employee of the organisation</li>
        </ul>


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentythree" aria-expanded="true" aria-controls="collapseOne">In case of loan rejection, how soon can a customer re-apply?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentythree" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
        <div className="-body text-left clearfix ml-5 para">
        If a customer fulfils all the requirements, they can re-apply post 3 months after the original application is rejected. The loan approval is subject to verification and credit checks carried out by Myfin.


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        </div>
        </div>
        </div>
        </section>

        <section className="container Faq">
        
        <h3 className="text-left font-weight-bold ml-2 mt-4"> Documentation</h3>
        </section>
        <section className="container">
        <div className="row">
        <div className="col-lg-12">
        <div className="accordion mt-5" id="accordionExampleThree">
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentyFour" aria-expanded="true" aria-controls="collapseOne">What are the documents required to secure a loan?{/* <img src="ASSETS/PLUS.png"  /> */}
</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentyFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleThree">
        <div className="-body text-left clearfix ml-5 para">
       <p> Following documents are needed for the loan application. These documents need to be uploaded on the Myfin website and no hard copies are required. Customer can click a photo from their mobile phone and upload these documents. The photo size should not exceed 2MB. Jpeg and PNG formats are preferred.</p>
       <ul>
        <li>Aadhaar Card</li>
        <li>Pan Card </li>
        <li>Current Address Proof (in case it is different on Aadhaar )</li>
        <li>Bank Statement (last month)</li>
        <li>Photo</li>
        <li>Salary Slips (last 3 months)</li>
        <li>Security Cheque (Soft and Physical Copy)</li>
        </ul>


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentyfive" aria-expanded="true" aria-controls="collapseOne">Does a customer need to <br className="d-lg-none d-md-none d-block"/> upload documents every time they apply for a loan?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentyfive" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleThree">
        <div className="-body text-left clearfix ml-5 para">
        Documents once uploaded and verified are valid for 6 months. In case a customer re-applies for a loan during that time, documents do not need to be uploaded. New documents need to be uploaded post 6 months. 

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentysix" aria-expanded="true" aria-controls="collapseOne">Why does Myfin require the particular documents? Are they safe?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentysix" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleThree">
        <div className="-body text-left clearfix ml-5 para">
        Myfin requires certain documents to verify the customer’s credit worthiness and to determine their correct and true identity. The documents are extremely safe and are only used for verification and records purpose. The information in the concerned documents will never be compromised.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        </div>
        </div>
        </div>
        </section>

        <section className="container Faq">
        
        <h3 className="text-left font-weight-bold ml-2 mt-4">Interest Rates and Charges</h3>
        </section>
        <section className="container">
        <div className="row">
        <div className="col-lg-12">
        <div className="accordion mt-5" id="accordionExampleFour">
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentySeven" aria-expanded="true" aria-controls="collapseOne">What are the Interest Charges?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentySeven" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFour">
        <div className="-body text-left clearfix ml-5 para">
        <p>5-40 day loan: Interest charged is at 0.1% per day. The entire interest is deducted upfront from the loan amount that is disbursed. However, the interest is payable only on the actual number of days the loan amount is used. In case the loan amount is repaid earlier, then the excess interest deducted upfront is refunded back to the customer’s account. </p>

        <p>3-6 months loan: Interest charged is at 1.75% per month or 21% per annum on the full loan amount. This is the flat method of calculating interest and not on reducing balance method. Interest is paid monthly as a part of the EMIs over the tenure of the loan.</p>
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentyEight" aria-expanded="true" aria-controls="collapseOne">Are there any other charges except Interest charges?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentyEight" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFour">
        <div className="-body text-left clearfix ml-5 para">
        Yes, there is a nominal Processing Fee & Account Maintenance Fee. These are flat fixed rates depending on the amount and tenure of the loan. These charges can range from Rs. 50 to Rs. 3000. 

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseTwentyNine" aria-expanded="true" aria-controls="collapseOne">Is there any GST?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseTwentyNine" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFour">
        <div className="-body text-left clearfix ml-5 para">
        Yes, GST is charged @ 18% on the sum of Processing Fees and Account Maintenance Fees only.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirty" aria-expanded="true" aria-controls="collapseOne">Are there any charges for early Repayment?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirty" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFour">
        <div className="-body text-left clearfix ml-5 para">
        There are no charges applied on early repayment for 5 to 40 day loan. Since the interest and other charges are deducted before the loan disbursement, the additional interest amount will be refunded in case of early repayment. On 3 months and 6 months loans, there is a foreclosure charge of 2% plus GST on outstanding amount. For 3 months and 6 months loans, only PF and AMC charges are deducted before the loan disbursement.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtyone" aria-expanded="true" aria-controls="collapseOne">Are there any charges for late repayment?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtyone" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFour">
        <div className="-body text-left clearfix ml-5 para">
        Salary date is the repayment date. A grace period of 3 days is provided, post which late payment interest is charged. Late payment charges are applied at the rate of 0.067% per day.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        </div>
        </div>
        </div>
        </section>

        <section className="container Faq">
        
        <h3 className="text-left font-weight-bold ml-2 mt-4">Repayment</h3>
        </section>
        <section className="container">
        <div className="row">
        <div className="col-lg-12">
        <div className="accordion mt-5" id="accordionExampleFive">
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtyTwo" aria-expanded="true" aria-controls="collapseOne">How is the repayment done?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtyTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFive">
        <div className="-body text-left clearfix ml-5 para">
        Repayment of the loan is done by salary deduction.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtyThree" aria-expanded="true" aria-controls="collapseOne">Can the loan be repaid earlier and how?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtyThree" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFive">
        <div className="-body text-left clearfix ml-5 para">
        Yes, the repayment can be done before the salary deduction as well. The customer can pre pay the loan by making an NEFT/IMPS transfer to the Myfin account or by sending a cheque.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtyFour" aria-expanded="true" aria-controls="collapseOne">Can the customer choose or change the loan tenure?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtyFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFive">
        <div className="-body text-left clearfix ml-5 para">
        No, the loan tenure is fixed while applying for the loan.
However, the employee is free to take a loan again in the following month once the current loan has been repaid in full. There is no restriction in the number of times that one can take a loan in a year provided that at any time, there is only 1 loan outstanding with Myfin.


            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtyFive" aria-expanded="true" aria-controls="collapseOne">Can the customer make a  <br className="d-lg-none d-md-none d-none d-sm-block"/> partial payment against the loan?{/* <img src="ASSETS/PLUS.png"  /> */} </button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtyFive" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFive">
        <div className="-body text-left clearfix ml-5 para">
        No, partial payment is not allowed. The loan has to be paid in full. The customer can take a new loan post the closure of the current loan.

            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        <div className="FaqAccordion mt-2">
        <div className="row faqrow">
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
               <img src="ASSETS/icon.png" alt="" />
               </div>
               <div className="col-lg-11 col-md-11 col-sm-11 col-10">
        <div className="ques" id="headingOne">
        <h2 className="clearfix mb-0 text-left">
        <button className="btn btn-link text-left collapsed" data-toggle="collapse" data-target="#collapseThirtySix" aria-expanded="true" aria-controls="collapseOne">How soon can a customer reapply for a new loan?{/* <img src="ASSETS/PLUS.png"  /> */}</button>
        </h2>
        </div>
        </div>
        </div>
        <div id="collapseThirtySix" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExampleFive">
        <div className="-body text-left clearfix ml-5 para">
        As soon as the current loan is repaid, the customer is eligible to apply for a new loan.
            </div>
            </div>
        </div>
        <hr className="container-fluid Faqhr "></hr>
        </div>
        </div>
        </div>
        </section>
        <footer className="site-footer ml-lg-5">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                         
                          
                           <p className="text-lg-left text-center text-md-left">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left text-md-left  mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left text-md-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer>
        {/* <footer className="site-footer ">
               <div className="container ">
                   <div className="row ml-lg-2">
                       <div className="col-sm-12 col-md-4 col-lg-4 site-footer1 footerlogo">
                     
                       <div className="imgdiv mb-4">
                       <img src="ASSETS/Group 234.png" className="order-2 order-lg-1 mr-lg-0 " alt=""/>
                       
                         </div>
                          
                           <p className="text-lg-left text-center ">
                              Myfin - A simple 3 step online process to raise <br/> immediate money between 2 salary cycles. <br/> Everyone, very often, faces with a sudden <br/> requirement of funds.
                           </p>
                           <h5 className="text-dark text-lg-left mt-4">These could be for any of the following:</h5>
                           <p className="text-dark text-lg-left pb-2 pay"><span>◉</span>&nbsp;&nbsp;Pay Unforeseen Medical Bills <br/><span>◉</span>&nbsp;&nbsp;Make Credit card payments</p>
                          
                           <a href="#concepts"> <button className="btn btn-md apply3 text-white ">KNOW MORE</button></a>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-6 col-lg-3 col-sm-12 col-md-4 footer-row">
                           <h6 className="font-weight-bold d-none d-sm-block">QUICK LINKS</h6>
                           <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Home</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/about">About Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><a href="#team">Team</a></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">How we work</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Products</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Contact Us</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Privacy Policy</NavLink></li>
                               <li className="text-justify ml-lg-4 mt-1"><NavLink to="/">Terms & Conditions</NavLink></li>
                           </ul>
                       </div>
                       <div className="vertical-line d-none d-sm-block"></div>
                       <div className="col-12 col-lg-3 col-sm-12 col-md-3 footer-row">
                               <h6 className=" d-none d-sm-block">OUR PRODUCTS</h6>
                               <ul className="footer-links d-none d-sm-block mt-lg-4">
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Salaried</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Business</NavLink></li>
                               <li className="text-justify ml-lg-2 mt-1"><NavLink to="/">Professional</NavLink></li>
                               </ul>
                           
                               <h6 className=" ml-lg-4 mx-auto connect mt-lg-5 mt-3 pt-4 pt-lg-0">CONNECT WITH US</h6>
                                 <div className="row mt-3">
                                     <div className="col-lg-1 col-md-1 col-1 col-sm-1 footerimg">
                                         </div>
                                     <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ml-3">
                                         <img src="ASSETS/Group 79.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg">
                                         <img src="ASSETS/Group 80.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 61.png" alt=""/>
                                         </div>
                                         <div className="col-lg-2 col-2 col-md-3 col-sm-2 footerimg ">
                                         <img src="ASSETS/Group 63.png" alt=""/>
                                         </div>
                                     </div>
                            </div>
                   </div>
               </div>
                </footer> */}
                <div className="container-fluid lastfooter">
                   <div className="row ">
                       <div className="col-lg-12 col-xl-7 col-sm-6 col-xs-12 col-12 copyright1">
                           <p className="copyright-text text-white pt-2">All rights reserved. | Privacy Policy. | Copyright with Fiscus Services Private Limited.</p>
                       </div>
                      
                       <div className="col-xl-5 col-md-5 col-lg-12 col-sm-6 col-12 copyright2">
                           <p className="copyright-text text-white pt-2">Developed by : <a className='text-white' href="http://www.aprilinnovations.com/" target="_blank">April Innovations</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className="d-lg-none d-md-none d-block"/>Designed by : Beak Media</p>
                       </div>
                   </div>
               </div> 
        </>
    )
}

export default Faq
